<template>
  <div style="margin-top: -50px">
    <div class="row">
      <div class="col-10">
        <p>
          <i
            style="font-size: 22px; color: #072648 !important"
            class="mdi mdi-database"
          ></i>
          <span style="font-size: 18px" class="font-weight-light">
            Le processus :
          </span>
          <span style="font-size: 22px">{{ items.name }}</span>

          <br />
        </p>
      </div>
    </div>

    <div class="row p-4" style="margin-top: -40px">
      <div class="col-12 p-0">
        <h4 class="card-title mt-4 mb-1">Liste des processus :</h4>
        <hr />
      </div>
    </div>
    <div class="row" style="margin-top: -20px">
      <div class="col-12">
        <b-table
          responsive
          :items="items.sousprocessus"
          :fields="subProcesses.tableFields"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          bordered
          striped
        >
          <template v-slot:cell(id)="data">
            <span
              style="text-decoration: underline; color: blue; cursor: pointer"
              >{{ "SP" + data.item.id }}</span
            >
          </template>
          <template v-slot:cell(taux)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :style="`background:${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };font-size:8px;`"
                :value="60"
                :label="`${60}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              @click="
                $router.push({
                  name: 'show-job-process',
                  params: {
                    slug: data.item.slug ? data.item.slug : '8787',
                    id: data.item.id,
                  },
                })
              "
              >{{ data.value }}</b-link
            >
          </template>
          <template v-slot:cell(sousprocessuss)="data">
            <div class="text-center">
              <b-badge
                variant="primary"
                pill
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                >{{ data.value.length }}</b-badge
              >
            </div>
          </template>
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    selectedView: 0,
    perPage: 4,
    currentPage: 1,
    sortDesc: false,
    sortBy: "",
    sortByFormatted: true,
    filterByFormatted: true,
    subProcesses: {
      tableItems: [
        {
          code: "D.1",
          label: "RH",
          level: 3,
          attachment_process: "Support",
          filling_rate: 60,
        },
      ],
      tableFields: [
        { key: "id", label: "Code", sortable: true },
        { key: "name", label: "Processus", sortable: true },
        { key: "niveau", label: "Niveau", sortable: true },
        {
          key: "sousprocessuss",
          label: "Processus de rattachement",
          sortable: true,
        },
        { key: "taux", label: "Taux de remplissage", sortable: true },
      ],
    },
    applications: {
      tableItems: [
        {
          code: "QM1",
          label: "CRM Salesforce",
          si_capacity: "SI - Distru",
          filling_rate: 60,
          po: "Didier Tribut",
        },
      ],
      tableFields: [
        { key: "id", label: "Code", sortable: true },
        { key: "name", label: "Libellé", sortable: true },
        {
          key: "libellecour",
          label: "Capacité SI Principale",
          sortable: true,
        },
        {
          key: "taux",
          label: "Taux de remplissage",
          sortable: true,
        },
        {
          key: "po",
          label: "PO",
          sortable: true,
        },
      ],
    },
  }),
  props: {
    items: Array,
  },
  created() {
    this.$store.dispatch("processusMetier/fetchProcessusMetier", this.items);
  },
  methods: {
    addrole() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("cofirmRole", this.selectrole);
        this.$emit("close");
      }
    },
  },
  computed: {
    ...mapGetters("processusMetier", ["PROCESSUS_METIER"]),
    PROCESSUS_METIER() {
      return this.PROCESSUS_METIER ? this.PROCESSUS_METIER.sousprocessus : [];
    },
    rows() {
      return this.items ? this.items.sousprocessus.length : 0;
    },
  },
};
</script>

<style>
.sub-modal[data-v-23747a02] {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  min-width: 80%;
  max-width: 80%;
  min-height: 95%;
  max-height: 95%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}
</style>
