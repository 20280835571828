<template>
  <section class="tables">
    <div class="row">
      <div class="col-12"><p class="h3 mb-3">Liste des objectifs</p></div>
    </div>
    <hr />

    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :fields="tableFields"
        >
          <template v-slot:cell(progress)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(responsable)="data">
            <div>
              <b-avatar
                v-b-tooltip.hover="data.value.full_name"
                variant="primary"
                size="2rem"
                :src="data.value.img"
                class="mr-1"
              ></b-avatar>
              {{ data.value.name }}
            </div>
          </template>

          <template v-slot:cell(actions)="data">
            <table-actions :actions="data.value" />
          </template>
        </b-table>
        <b-pagination aria-controls="table-list" align="right"> </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions },
  data: function () {
    return {
      tableFields: [
        { key: "Organisation", sortable: true },
        { key: "Responsable", sortable: true },
        { key: "goal", label: "Objectif stratégique", sortable: true },
        { key: "Description", sortable: false },
        { key: "Vision", sortable: false },
        { key: "Alignement", sortable: true },
        { key: "Kpis", sortable: false },
      ],
      tableItems: [
        {
          Organisation: "Orga",
          Responsable: {
            name: "S.E",
            full_name: "Steve Ewore",
            img: "https://source.unsplash.com/random/150x150",
          },
          goal: "Objectif 1",
          Description: "Description objectif 1",
          Alignement: "6",
          Kpis: "Kpi-48524-213",
          Vision: "Visio 12",
        },
        {
          Organisation: "Orga",
          Responsable: {
            name: "S.E",
            full_name: "Steve Ewore",
            img: "https://source.unsplash.com/random/150x150",
          },
          goal: "Objectif 2",
          Description: "Description objectif 2",
          Alignement: "6",
          Kpis: "Kpi-48524-213",
          Vision: "Visio 12",
        },
        {
          Organisation: "Orga",
          Responsable: {
            name: "S.E",
            full_name: "Steve Ewore",
            img: "https://source.unsplash.com/random/150x150",
          },
          goal: "Objectif 3",
          Description: "Description objectif 3",
          Alignement: "6",
          Kpis: "Kpi-48524-213",
          Vision: "Visio 12",
        },
      ],
    };
  },
};
</script>
