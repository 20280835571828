<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <div class="d-flex mb-2 col-4 p-0 my-auto">
          <div class="my-auto mr-2">
            <span class="h4 text-secondary mr-auto my-auto"
              >Liste des applications</span
            >
          </div>
          <DropDownCustom
            class="my-auto"
            :items="['Tableau', 'Graphique']"
            @changeValue="
              (value) =>
                this.$store.dispatch('views/changeApplicationActiveView', value)
            "
            :value="$store.state.views.applicationActiveView"
          />
        </div>
        <div class="row col-8 my-auto pl-0">
          <div class="my-auto col-4 px-1">
            <b-button
              :disabled="disabled"
              variant="outline-secondary"
              class="my-auto w-100 px-0"
              @click="myApplications = !myApplications"
              >Mon portfeuille d'applications
            </b-button>
          </div>
          <div class="my-auto col-4 px-1">
            <CustomAddButton
              @click="
                () => {
                  disabled ? '' : (showAddApplication = true);
                }
              "
              class="my-auto w-100"
              text="Ajouter application"
              :disabled="disabled"
            />
          </div>

          <div class="my-auto col-4 px-1">
            <CustomAddButton
              @click="addItem"
              class="my-auto w-100"
              text="Créer application"
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
      </div>
    </div>

    <div v-if="$store.state.views.applicationActiveView == 0">
      <div v-if="disabled == true" class="row mb-3">
        <div class="col-12 d-flex flex-wrap">
          <div class="pr-2" style="width: 150px">
            <b-form-select
              disabled
              class="mr-2 h-100"
              :options="['Statut SA', 'Statut 1']"
            ></b-form-select>
          </div>
          <div class="pr-2" style="width: 150px">
            <b-form-select
              disabled
              class="mr-2 h-100"
              :options="['PO', 'Tous']"
            ></b-form-select>
          </div>
          <div class="pr-2" style="width: 150px">
            <b-form-input
              disabled
              class="mr-2"
              type="text"
              placeholder="Application"
            ></b-form-input>
          </div>
        </div>
      </div>
      <div v-else class="row mb-3">
        <div class="col-12 d-flex flex-wrap">
          <div class="pr-2" style="width: 200px">
            <v-select
              :options="['En prod', 'En cours', 'Terminé']"
              append-to-body
              v-model="statut"
              type="text"
              placeholder="Statut"
            >
              <template #no-options> Liste vide</template>
            </v-select>
          </div>
          <div class="pr-2" style="width: 200px">
            <v-select
              v-model="responsable"
              class="mr-2 h-100"
              :options="responsables"
              :reduce="(responsable) => responsable.id"
              placeholder="Po"
              label="value"
            >
              <template #no-options> Liste vide</template>
            </v-select>
          </div>
          <div class="pr-2" style="width: 200px">
            <b-form-input
              class="mr-2"
              type="text"
              placeholder="Application"
              v-model="name"
            ></b-form-input>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            :items="filtredApplications"
            :fields="applications.tableFields"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :busy="loadingData"
            show-empty
            responsive
            bordered
            striped
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- SHOW IF TABLE IS EMPTY -->
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(ref_sa)="data">
              <b-link
                v-if="!data.item.hasOwnProperty('editMode')"
                max="100"
                show-progress
                height="15px"
                style="text-decoration: underline; color: blue; cursor: pointer"
                :to="{
                  name: 'show-application',
                  params: {
                    id: data.item.id,
                    slug: data.item.slug || 'abcde',
                  },
                }"
              >
                {{ data.value }}
              </b-link>
              <b-form-input
                type="text"
                placeholder="Réf. SA"
                v-else
                disabled
              ></b-form-input>
            </template>

            <template v-slot:cell(label_court_sa)="data">
              <span
                v-if="!data.item.hasOwnProperty('editMode')"
                max="100"
                show-progress
                height="15px"
              >
                {{ data.value }}
              </span>
              <div v-else>
                <b-form-input
                  type="text"
                  v-model="newApplication.libelle_court"
                  placeholder="Libellé court SA"
                  :class="{
                    'is-invalid':
                      $v.newApplication.libelle_court.$error &&
                      $v.newApplication.libelle_court.$dirty,
                  }"
                ></b-form-input>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newApplication.libelle_court.$dirty"
                >
                  {{
                    !$v.newApplication.libelle_court.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
              </div>
            </template>

            <template v-slot:cell(label_long_sa)="data">
              <b-link
                v-if="!data.item.hasOwnProperty('editMode')"
                :to="{
                  name: 'show-application',
                  params: {
                    id: data.item.id,
                    slug: data.item.slug,
                  },
                }"
                >{{ data.value }}
              </b-link>
              <div v-else>
                <b-form-input
                  type="text"
                  v-model="newApplication.libelle"
                  placeholder="Libellé SA"
                  :class="{
                    'is-invalid':
                      $v.newApplication.libelle.$error &&
                      $v.newApplication.libelle.$dirty,
                  }"
                ></b-form-input>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newApplication.libelle.$dirty"
                >
                  {{
                    !$v.newApplication.libelle.required
                      ? "Champ obligatoire"
                      : !$v.newApplication.libelle.minLength
                      ? `Le champ doit contenir au moins ${$v.newApplication.libelle.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
              </div>
            </template>

            <template v-slot:cell(si_capacity)="data">
              <div
                v-if="!data.item.hasOwnProperty('editMode')"
                class="text-center"
              >
                <b-badge
                  variant="primary"
                  pill
                  @click="listapplicationmethods(data.item)"
                  style="
                    cursor: pointer;
                    background-color: #adb5bd;
                    border-color: #adb5bd;
                  "
                  >{{ data.value }}
                </b-badge>
              </div>
              <div class="text-center" v-else style="">
                <b-badge
                  style="
                    cursor: pointer;
                    background-color: #adb5bd;
                    border-color: #adb5bd;
                  "
                  pill
                  @click="Initiativesselect(data.item)"
                  >{{ listcapacteSI.length == 0 ? "+" : listcapacteSI.length }}
                </b-badge>
              </div>
              <!--              <multiselect   label="text"-->
              <!--                              track-by="value"-->
              <!--                           :options="capaciteSI"   multiple-->
              <!--                v-model="newApplication.capacite" placeholder="CapacitéSI" v-else> </multiselect>-->
            </template>

            <template v-slot:cell(po)="data">
              <TableResponsable
                v-if="!data.item.hasOwnProperty('editMode')"
                :responsable="data.value"
              ></TableResponsable>

              <div v-else>
                <v-select
                  label="value"
                  append-to-body
                  :options="responsables"
                  :reduce="(responsable) => responsable.id"
                  placeholder="po"
                  v-model="newApplication.po"
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newApplication.po.$error &&
                      $v.newApplication.po.$dirty,
                  }"
                >
                  <template #no-options> Liste vide</template>
                </v-select>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newApplication.po.$dirty"
                >
                  {{
                    !$v.newApplication.po.required ? "Champ obligatoire" : ""
                  }}
                </span>
              </div>
            </template>

            <template v-slot:cell(status)="data">
              <span
                v-if="!data.item.hasOwnProperty('editMode')"
                max="100"
                show-progress
                height="15px"
              >
                {{ data.value }}
              </span>
              <div v-else>
                <v-select
                  append-to-body
                  :options="['En prod', 'En cours', 'Terminé']"
                  v-model="newApplication.statut"
                  type="text"
                  placeholder="Statut"
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newApplication.statut.$error &&
                      $v.newApplication.statut.$dirty,
                  }"
                >
                  <template #no-options> Liste vide</template>
                </v-select>
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newApplication.statut.$dirty"
                >
                  {{
                    !$v.newApplication.statut.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
              </div>
            </template>

            <template v-slot:cell(taux)="data">
              <b-progress
                v-if="!data.item.hasOwnProperty('editMode')"
                max="100"
                show-progress
                height="15px"
              >
                <b-progress-bar
                  :style="`background:${
                    data.value > 20 && data.value <= 70
                      ? '#f5f245'
                      : data.value > 70
                      ? '#23d92c'
                      : '#f5ba45'
                  };font-size:8px;`"
                  :value="data.value"
                  :label="`${data.value}%`"
                ></b-progress-bar>
              </b-progress>
              <div v-else>
                <b-progress max="100" show-progress height="15px">
                  <b-progress-bar
                    style="font-size: 8px"
                    :value="0"
                    :label="`${0}%`"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </template>

            <template #cell(actions)="data">
              <table-actions
                :actions="disabled ? [] : data.value"
                :editMode="data.item.hasOwnProperty('editMode')"
                @cancelItem="cancelItem(data.item, data.index)"
                @deleteItem="deleteApplication(data.item.id)"
                @confirmItem="addApplication"
                @transferItem="transferapp(data.item)"
              ></table-actions>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="table-list"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>
    <GraphicViewApplication :items="tableItems" v-else />

    <div v-if="showSa" to="modals-xyz-548">
      <Modal @close="showSa = !showSa">
        <div class="row">
          <div class="col-12">
            <p class="h3">Fiche processus</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="my-3">
              <p class="font-weight-bold mr-3">Réf. SA:</p>
              <span>{{ "SA" + datashow.id }}</span>
            </div>
            <div class="my-3">
              <p class="font-weight-bold mr-3">Libellé court SA:</p>
              <span>{{ datashow.label_court_sa }}</span>
            </div>
            <div class="my-3">
              <p class="font-weight-bold mr-3">Libellé SA:</p>
              <span>{{ datashow.name }}</span>
            </div>
            <div class="my-3">
              <p class="font-weight-bold mr-3">Statut:</p>
              <span>{{ datashow.status }}</span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="my-3">
              <p class="font-weight-bold mr-3">Capacité SI:</p>
              <div v-if="datashow.capaciteSis">
                <span
                  :key="index"
                  v-for="(item, index) in datashow.capaciteSis"
                >
                  {{ item.name + " " }}
                </span>
              </div>
            </div>
            <div class="my-3">
              <p class="font-weight-bold mr-3">PO:</p>
              <span>{{
                datashow.po.firstname + " " + datashow.po.lastname
              }}</span>
            </div>
            <div class="my-3">
              <p class="font-weight-bold mr-3">Taux de remplissage:</p>
              <b-progress-bar
                :value="datashow.taux"
                :style="`background:${
                  datashow.taux > 20 && datashow.taux <= 70
                    ? '#f5f245'
                    : datashow.taux > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };`"
                height="1rem"
                :label="datashow.taux + '%'"
              ></b-progress-bar>
            </div>
          </div>
        </div>
      </Modal>
    </div>
    <div v-if="showApplicationProcessList" to="modals-xyz-548">
      <modal @close="showApplicationProcessList = !showApplicationProcessList">
        <CapaciteList :capacite="listCapacite" />
      </modal>
    </div>
    <div v-if="transferProcess" to="modals-xyz-548">
      <modal @close="transferProcess = !transferProcess">
        <TransferApplication
          :items="dataapplication"
          :libelle="libelle"
          @transferapplication="transferapplication"
        />
      </modal>
    </div>
    <div v-if="showapplication" to="modals-xyz-548">
      <modal @close="showapplication = !showapplication">
        <Capaciteapplication
          :libelle="libelle"
          @ConfirmCap="confirmCapacite"
          :selected="Capacite"
          @close="showapplication = !showapplication"
        ></Capaciteapplication>
      </modal>
    </div>

    <div v-if="showAddApplication" to="modals-xyz-548">
      <modal @close="showAddApplication = !showAddApplication">
        <AddApplication
          :patrimoine="APPLICATION"
          @close="showAddApplication = !showAddApplication"
          :libelle="libelle"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "@/components/custom/DropDown.vue";
import TableActions from "@/components/custom/TableActions.vue";
import GraphicViewApplication from "./GraphicViewApplication";
import Modal from "@/components/custom/Modal.vue";
import TableResponsable from "@/components/custom/TableResponsable";
import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import TransferApplication from "./TransferApplication";
import moment from "moment";
import CapaciteList from "./CapaciteList";
import Capaciteapplication from "./Capaciteapplication.vue";
import CustomAddButton from "@/components/custom/CustomAddButton";
import AddApplication from "./AddApplication.vue";

export default {
  components: {
    DropDownCustom,
    TableActions,
    Modal,
    GraphicViewApplication,
    TransferApplication,
    CustomAddButton,
    TableResponsable,
    CapaciteList,
    Capaciteapplication,
    AddApplication,
  },
  props: {
    disabled: Boolean,
    items: Array,
    libelle: String,
  },
  data: () => ({
    Capacite: [],
    listcapacteSI: [],
    dataapplication: null,
    showApplicationProcessList: false,
    statut: "",
    showGraf: 0,
    showapplication: false,
    name: "",
    datashow: "",
    responsable: "",
    newApplication: {
      libelle_court: "",
      libelle: "",
      statut: "",
      capacite: null,
      po: "",
      taux: 0,
    },
    listCapacite: null,
    addItemtable: false,
    showSa: false,
    transferProcess: false,
    tableItems: [],
    sortBy: "",
    loadingData: false,
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    myApplications: false,
    applications: {
      tableItems: [],
      tableFields: [
        { key: "ref_sa", label: "Réf. SA", sortable: true },
        { key: "label_court_sa", label: "Libellé court SA", sortable: true },
        { key: "label_long_sa", label: "Application", sortable: true },
        {
          key: "status",
          label: "Statut",
          sortable: true,
        },
        {
          key: "si_capacity",
          label: "Capacité SI",
          sortable: true,
        },
        {
          key: "po",
          label: "PO",
          sortable: true,
        },
        {
          key: "taux",
          label: "Taux de remplissage",
          sortable: true,
        },
        {
          key: "actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          label: "Actions",
        },
      ],
    },
    showAddApplication: false,
  }),
  validations: {
    newApplication: {
      libelle: {
        required,
        minLength: minLength(5),
      },
      libelle_court: {
        required,
      },
      po: {
        required,
      },
      statut: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store.dispatch("users/fetchAllResponsables");
      this.$store
        .dispatch("patrimoine/fetchApplications", this.$route.params.id)
        .then(() => {
          this.loadingData = false;
        });
      this.$store.dispatch("capaciteSi/fetchAllCapacites");
    } else {
      this.loadingData = false;
    }
  },
  methods: {
    confirmCapacite(data) {
      this.listSI = data;
    },
    listapplicationmethods(item) {
      this.$store
        .dispatch("application/fetchApplicationCapacites", item.id)
        .then(() => {
          if (item.capaciteSis.length > 0) {
            var data = {
              name: item.name,
              capacites: this.CAPACITES,
            };
            this.listCapacite = data;
            this.showApplicationProcessList = !this.showApplicationProcessList;
          }
        });
    },
    Initiativesselect() {
      this.showapplication = !this.showapplication;
      var push = this.listcapacteSI;
      this.Capacite = push;
    },

    transferapplication(item) {
      this.transferProcess = !this.transferProcess;
      Swal.fire("L'application a bien été transférée !", "", "success");
      this.$store.dispatch("application/updateApplication", item).then(() => {
        this.$store.dispatch(
          "patrimoine/fetchApplications",
          this.$route.params.id
        );
      });
    },
    onValueChange(value) {
      this.showGraf = value;
    },
    cancelItem() {
      if (
        this.newApplication.statut == "" &&
        this.newApplication.po == "" &&
        this.newApplication.libelle == "" &&
        this.newApplication.libelle_court == ""
      ) {
        // this.tableItems = this.tableItems.filter(
        //   (item, indexitem) => indexitem !== this.tableItems.length - 1
        // );
        this.$store.dispatch(
          "patrimoine/fetchApplications",
          this.$route.params.id
        );
      } else {
        this.newApplication.statut = "";
        this.newApplication.libelle = "";
        this.newApplication.libelle_court = "";
        this.newApplication.po = "";
        this.listcapacteSI = [];
      }
    },
    showitem(data) {
      if (data) {
        this.datashow = data;
        this.showSa = !this.showSa;
      }
    },
    addItem() {
      if (!this.disabled) {
        var data = this.tableItems.find(
          (contributer) => contributer.editMode == true
        );
        if (data == null) {
          this.currentPage = 1;
          this.tableItems.unshift({ editMode: true });
          this.$store.dispatch("views/changeApplicationActiveView", 0);
        }
      }
    },
    addApplication() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var date = new Date();
        var item = {
          name: this.newApplication.libelle,
          patrimoine: `/api/referentiel/patrimoines/${this.$route.params.id}`,
          libellecour: this.newApplication.libelle_court,
          capaciteSis: this.listcapacteSI ? this.listcapacteSI : [],
          status: this.newApplication.statut,
          po: `/api/users/${this.newApplication.po}`,
          taux: this.newApplication.taux ? this.newApplication.taux : 0,
          description: " ",
          validateDate: moment(date).format("DD/MM/YYYY"),
        };
        this.$store.dispatch("application/createApplication", item).then(() => {
          Swal.fire("L'application est bien créée!", "", "success");
          this.$store
            .dispatch("patrimoine/fetchApplications", this.$route.params.id)
            .then(() => {
              this.newApplication.statut = "";
              this.newApplication.libelle = "";
              this.newApplication.libelle_court = "";
              this.newApplication.po = "";
              this.listcapacteSI = [];
            });
        });
      }
    },
    deleteApplication: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.tableItems = this.tableItems.filter((item) => item.id !== id);

          this.$store
            .dispatch("application/updateApplication", {
              id: id,
              patrimoine: null,
            })
            .then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
        }
      });
    },
    transferapp(id) {
      this.dataapplication = id;
      this.transferProcess = !this.transferProcess;
    },
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("capaciteSi", ["CAPACITES_SI"]),
    ...mapGetters("application", ["CAPACITES"]),
    ...mapGetters("patrimoine", ["APPLICATION"]),
    ...mapGetters(["AuthenticatedUser"]),

    rows() {
      return this.tableItems ? this.tableItems.length : 0;
    },
    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));
      return data;
    },
    capaciteSI() {
      var data = this.CAPACITES_SI.map((capacite) => ({
        value: `/api/referentiel/capacite_sis/${capacite.id}`,
        text: capacite.name,
      }));
      return data;
    },
    filtredApplications() {
      return this.tableItems
        .map((data) => ({
          ...data,
          ref_sa: "SA" + data.id,
          label_court_sa: data.libellecour,
          label_long_sa: data.name,
          si_capacity: data.capaciteSis ? data.capaciteSis.length : 0,
          actions: ["delete", "transfer"],
        }))
        .filter((application) =>
          this.myApplications && this.AuthenticatedUser && application.po
            ? this.AuthenticatedUser.id === application.po.id
            : true
        )
        .filter((application) =>
          application.name
            ? application.name.toLowerCase().indexOf(this.name.toLowerCase()) >
              -1
            : true
        )
        .filter((application) =>
          this.responsable ? application.po.id === this.responsable : true
        )
        .filter((application) =>
          this.statut ? application.status === this.statut : true
        );
    },
  },
  watch: {
    APPLICATION: function () {
      this.tableItems = this.APPLICATION ? this.APPLICATION.applications : [];
    },
  },
};
</script>

<style>
.multiselect--active {
  z-index: 20;
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: unset;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
</style>
