<template>
  <div style="margin-top: -50px">
    <p class="text-secondary my-auto mr-auto">
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Patrimoine :
      </span>
      <span style="font-size: 22px">{{ libelle.name }}</span>
    </p>
    <br />
    <p class="h3">
      Ajouter un processus :
      <span style="font-size: 22px">{{ items.name }}</span>
    </p>

    <hr />
    <div class="d-flex align-items-center pb-2 pt-0">
      <span class="d-inline-block w-75 h4 mb-0">Recherche processus:</span>
      <b-form-input placeholder="Processus" v-model="patrimoine.name" />
      <b-button
        class="ml-1"
        @click="addrole"
        :disabled="selectedRole === null"
        type=""
      >
        Confirmer
      </b-button>
    </div>

    <b-table
      :fields="fields"
      :items="PROCESSUS"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loadingData"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedRole"
            :value="data.item.id"
          ></b-form-radio>
        </div>
      </template>
      <template #cell(value)="data">
        <span> {{ data.value }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedRole: null,
      selectedUser: null,
      loadingData: true,
      selectrole: "",
      patrimoine: {
        name: "",
      },
      fields: [
        { key: "value", label: "Processus" },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  validations: {
    selectrole: {
      required,
    },
  },
  created() {
    this.$store
      .dispatch("processusMetier/fetchAllProcessusMetiers")
      .then(() => {
        this.loadingData = false;
      });
  },
  methods: {
    addrole() {
      var res = [];
      for (var i = 0; i < this.items.length; i++) {
        res[i] = this.items[i]["@id"];
      }
      res.push(`/api/referentiel/processus_metiers/${this.selectedRole}`);
      var item = {
        id: this.$route.params.id,
        processus: res,
      };
      this.$emit("addprocessus", item);
    },
  },
  computed: {
    ...mapGetters("processusMetier", ["PROCESSUS_METIERS"]),
    rows() {
      return this.PROCESSUS.length;
    },

    PROCESSUS() {
      var data = this.PROCESSUS_METIERS.filter(
        (processus) =>
          !this.items.filter((item) => item.id === processus.id).length &&
          !processus.parent
      )
        .map((procesus) => ({
          id: procesus.id,
          value: procesus.name,
        }))
        .filter(
          (processus) =>
            processus.value
              .toLowerCase()
              .indexOf(this.patrimoine.name.toLowerCase()) > -1
        );
      return data;
    },
  },
  props: {
    items: Array,
    libelle: String,
  },
};
</script>

<style></style>
