<template>
  <div class="row card m-0">
    <div class="col-12">
      <div class="p-2 d-flex flex-column">
        <!-- {{ groupBy(items, "capaciteMetiers.id") }} -->
        <div
          v-if="!items.length"
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
        <div
          v-else
          :key="index"
          v-for="(item, index) in groupBy(items, 'capaciteMetiers.id')"
        >
          <p class="mt-3 mb-2 h5">
            <!-- {{ item.capaciteMetiers ? item.capaciteMetiers.name : "N/A" }} -->
            {{ item[0].capaciteMetiers ? item[0].capaciteMetiers.name : "N/A" }}
          </p>
          <div
            class="text-white row p-2 rounded my-2"
            style="background: #f2edf3"
          >
            <div
              v-for="(process, i) in item"
              :key="'p' + i"
              class="col-12 col-md-3 p-1"
            >
              <div class="p-1 bg-gradient-success rounded h-100">
                <p class="my-2 h5 text-center">
                  <b-link
                    style="text-decoration: none; color: white"
                    :to="{
                      name: '',
                      params: {
                        id: process.id,
                        slug: process.slug || 'abcde',
                      },
                    }"
                    >{{ process.name }}</b-link
                  >
                </p>
                <!-- <p class="my-2 h5 text-center">{{ item.name }}</p> -->
                <div
                  class="mb-2"
                  :key="appIndex"
                  v-for="(processus, appIndex) in process.sousprocessus"
                >
                  <div class="h-100 p-2">
                    <p class="mb-0">
                      <b-link
                        style="text-decoration: none; color: white"
                        :to="{
                          name: '',
                          params: {
                            id: processus.id,
                            slug: processus.slug || 'abcde',
                          },
                        }"
                        >{{ appIndex + 1 }} -
                        {{ processus.name || processus }}</b-link
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    items: Array,
  },
  methods: {
    groupBy,
  },
};
</script>

