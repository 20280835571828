var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-50px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('p',[_c('i',{staticClass:"mdi mdi-database",staticStyle:{"font-size":"22px","color":"#072648 !important"}}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"18px"}},[_vm._v(" Le processus : ")]),_c('span',{staticStyle:{"font-size":"22px"}},[_vm._v(_vm._s(_vm.items.name))]),_c('br')])])]),_vm._m(0),_c('div',{staticClass:"row",staticStyle:{"margin-top":"-20px"}},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"responsive":"","items":_vm.items.sousprocessus,"fields":_vm.subProcesses.tableFields,"show-empty":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{staticStyle:{"text-decoration":"underline","color":"blue","cursor":"pointer"}},[_vm._v(_vm._s("SP" + data.item.id))])]}},{key:"cell(taux)",fn:function(data){return [_c('b-progress',{attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background:${
                data.value > 20 && data.value <= 70
                  ? '#f5f245'
                  : data.value > 70
                  ? '#23d92c'
                  : '#f5ba45'
              };font-size:8px;`),attrs:{"value":60,"label":`${60}%`}})],1)]}},{key:"cell(name)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.$router.push({
                name: 'show-job-process',
                params: {
                  slug: data.item.slug ? data.item.slug : '8787',
                  id: data.item.id,
                },
              })}}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(sousprocessuss)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"variant":"primary","pill":""}},[_vm._v(_vm._s(data.value.length))])],1)]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row p-4",staticStyle:{"margin-top":"-40px"}},[_c('div',{staticClass:"col-12 p-0"},[_c('h4',{staticClass:"card-title mt-4 mb-1"},[_vm._v("Liste des processus :")]),_c('hr')])])
}]

export { render, staticRenderFns }