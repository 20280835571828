<template>
  <div class="row card m-0">
    <div class="col-12">
      <div class="p-2 d-flex flex-column">
        <div
          v-if="!items.length"
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
        <div
          :key="index"
          v-for="(item, index) in groupBy(items, 'capaciteSis[0][@id]')"
        >
          <p
            class="mt-3 mb-2 h5"
            v-if="item[0].capaciteSis && item[0].capaciteSis.length"
          >
            {{ item[0].capaciteSis[0].name }}
          </p>
          <p class="mt-3 mb-2 h5" v-else>N/A</p>
          <div
            class="text-white row p-2 rounded my-2 py-3"
            style="background: #f2edf3"
          >
            <div
              v-if="!item.length"
              class="text-gray d-flex h2 text-center w-100"
              style="height: 166px"
            >
              <i
                class="mdi my-auto ml-auto mdi-information-outline my-auto"
              ></i>
              <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
            </div>

            <div
              class="col-12 col-md-3 mb-2"
              v-for="(application, appIndex) in item"
              :key="appIndex"
            >
              <div class="bg-gradient-success rounded h-100 py-2 px-4">
                <p class="mb-0">
                  <b-link
                    style="text-decoration: none; color: white"
                    :to="{
                      name: 'show-application',
                      params: {
                        id: application.id,
                        slug: application.slug || 'abcde',
                      },
                    }"
                  >
                    {{ application.name }}</b-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    groupBy,
  },
};
</script>

<style></style>
