<template>
  <section class="tables" style="margin-top: -80px">
    <div class="row p-4">
      <div class="col-10 p-0">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Capacité :
          </span>
          <span style="font-size: 22px">{{ capacite.name }}</span>
        </p>
      </div>
    </div>
    <div class="row p-4" style="margin-top: -40px">
      <div class="col-12 p-0">
        <h4 class="card-title mt-4 mb-1">Liste des capacités SI :</h4>
        <hr />
      </div>
    </div>
    <div class="row" style="margin-top: -20px">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-2">
            <b-table
              :items="capacite.capacites.capaciteSis"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="tableFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(status)="data">
                <span class="text-primary">{{ data.value }}</span>
              </template>
              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-infosys-capacity',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug || 'abcde',
                    },
                  }"
                  >{{ data.value }}</b-link
                >
              </template>

              <template #cell(niveau)="data">
                <span>{{ data.value }}</span>
              </template>

              <template #cell(sousCapacites)="data">
                <div class="text-center">
                  <b-badge
                    v-if="data.value.length"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    @click="$emit('showSousCapacite', data.item)"
                    >{{ uniqBy(data.value, "id").length || 0 }}</b-badge
                  >
                  <b-badge
                    v-else
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    >{{ uniqBy(data.value, "id").length }}</b-badge
                  >
                </div>
              </template>

              <template v-slot:cell(applications)="data">
                <div class="text-center">
                  <b-badge
                    v-if="data.value.length"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    @click="$emit('showGoals', data.item)"
                    >{{ data.value.length || 0 }}</b-badge
                  >
                  <b-badge
                    v-else
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    >{{ data.value.length }}</b-badge
                  >
                </div>
              </template>

              <template #cell(actions)="data">
                <table-actions
                  :actions="['delete']"
                  :editMode="data.item.hasOwnProperty('editMode')"
                  @showItem="
                    $router.push({
                      name: 'show-infosys-capacity',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug || 'abcde',
                      },
                    })
                  "
                  @deleteItem="deleteItem(data.item.id)"
                />
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import { uniqBy } from "lodash";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {},
  props: {
    capacite: Object,
  },

  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "name", label: "Capacité SI", sortable: true },
        {
          key: "sousCapacites",
          label: "Capacité de rattachement",
          sortable: true,
        },
        { key: "niveau", label: "Niveau", sortable: true },
        {
          key: "applications",
          label: "Applications",
          sortable: true,
        },
        // {
        //   key: "actions",
        //   label: "",
        //   thStyle: { width: "50px !important", minWidth: "30px !important" },

        //   sortable: false,
        // },
      ],
      tableItems: [],
    };
  },
  methods: {
    uniqBy,
  },
  computed: {
    rows() {
      return this.capacite.capacites.capaciteSis.length;
    },
  },
};
</script>
