<template>
  <div>
    <div v-if="disabled" class="row mb-3">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <div class="d-flex">
          <div class="my-auto mr-2">
            <span class="h4 text-secondary mr-auto my-auto"
              >Liste des transformations</span
            >
          </div>
          <div v-if="selected !== 1" class="my-auto">
          <DropDownCustom
            :items="['Tous', 'Application', 'Composant technique']"
            @changeValue="onFilterChange"
            :selected="selectedFilter"
          />
        </div>
        <div class="ml-1 my-auto">
          <DropDownCustom
            :items="filteredViews"
            :selected="selected"
            @changeValue="onViewChange"
          />
        </div>

        </div>
        
        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
      </div>
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <div class="d-flex flex-wrap">
          <div class="pr-5 ml-2">
            <v-select
              disabled
              class="mr-2 mb-2"
              style="min-width: 150px; max-width: 200px"
              :options="['BL 1', 'BL 2', 'BL 3']"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>
          <div class="pr-2">
            <b-form-input
              disabled=""
              type="year"
              placeholder="Plan de transformation"
              format="YYYY"
              class="mr-2 mb-2"
              startView="years"
              minViewMode="years"
            ></b-form-input>
          </div>
        </div>
        <div class="w-md-50 d-flex flex-wrap justify-content-end"></div>
      </div>
    </div>
    <div v-else class="row mb-3">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <div class="d-flex">
          <div class="my-auto mr-2">
            <span class="h4 text-secondary mr-auto my-auto"
              >Liste des transformations</span
            >
          </div>
          <div v-if="selected !== 1" class="my-auto">
            <DropDownCustom
              :items="['Tous', 'Application', 'Composant technique']"
              @changeValue="onFilterChange"
              :selected="selectedFilter"
            />
          </div>
          <div class="ml-1 my-auto">
            <DropDownCustom
              :items="filteredViews"
              :selected="selected"
              @changeValue="onViewChange"
            />
          </div>
          <div class="my-auto pl-2 mx-0" v-if="selected == 1">
            <b-dropdown
              id="dropdown-1"
              :text="selectedView.text"
              :class="`custom-drop my-auto btn-outline-info ${selectedView.color} `"
            >
              <b-dropdown-item
                class="col-sm-6 col-md-auto m-0 p-0"
                v-for="(view, index) in ganttView"
                :key="index"
              >
                <button
                  @click="changeView(view, index)"
                  type="button"
                  :class="`btn ${view.color} btn-fw col-sm-12`"
                >
                  {{ view.text }}
                </button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="col-md-0 ml-auto p-0 d-flex justify-content-end">
          <!--          <b-button-->

          <!--              variant="secondary"-->
          <!--              class="mb-2 align-btn-table-lg-pr"-->
          <!--              @click="addinput"-->
          <!--              style="background-color: #007BFFFF!important; border-color:#007BFFFF!important "-->

          <!--          ><i class="mdi mdi-plus"></i> Ajouter une Transformation</b-button-->
          <!--          >-->
        </div>

        <div class="col-12 p-0">
          <hr class="mt-1 mb-3 bg-secondary" />
        </div>
      </div>
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <div class="d-flex flex-wrap">
          <div class="pr-1 my-auto">
            <v-select
              v-model="searchQuery.business"
              label="text"
              placeholder="Business line"
              :reduce="(bsl) => bsl.value"
              style="width: 200px"
              :options="businessLine"
            ></v-select>
          </div>
          <div class="pr-2 my-auto">
            <v-select
              :disabled="selected !== 2 || selectedFilter === 0"
              v-model="searchQuery.transformationPlan"
              placeholder="Plan de transformation"
              style="width: 200px"
              :options="['As-Is', 'Transformation', 'To Be']"
            ></v-select>
          </div>
        </div>
        <div class="w-md-50 d-flex flex-wrap justify-content-end"></div>
      </div>
    </div>
    <div class="row" v-show="selected === 0">
      <div class="col-12">
        <b-table
          responsive
          :items="tableItems"
          :fields="transformation.tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loadingData"
          show-empty
          bordered
          striped
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template v-slot:cell(fdr)="data">
            <b-link
              v-if="!data.item.hasOwnProperty('editMode')"
              :to="{
                name: 'show-fdr',
                params: {
                  id: data.value.id,
                  slug: data.value.slug || 'abcde',
                },
              }"
              >{{ data.value.name }}</b-link
            >
            <div v-else>
              <v-select
                label="text"
                append-to-body
                :options="fdr"
                :reduce="(fdr) => fdr.value"
                placeholder="Fdr"
                v-model="newTransformation.fdr"
                style="padding-right: 0 !important"
                @input="getinitaitive"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
          </template>
          <template v-slot:cell(initiative)="data">
            <b-link
              v-if="!data.item.hasOwnProperty('editMode')"
              @click="
                $router.push({
                  name: 'show-initiative',
                  params: {
                    id: data.value.id,
                    slug: data.value.slug || 'abcde',
                  },
                })
              "
              >{{ data.value.libelle }}</b-link
            >
            <div v-else>
              <v-select
                :disabled="initiatives ? false : true"
                label="text"
                append-to-body
                :options="initiatives"
                :reduce="(initiative) => initiative.value"
                placeholder="initiative"
                v-model="newTransformation.initiative"
                style="padding-right: 0 !important"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
          </template>
          <template v-slot:cell(createdAt)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">{{
              data.item
                ? moment(data.item.createdAt, "DD/MM/YYYY").format("DD/MM/YYYY")
                : "-"
            }}</span>
            <div v-else>
              <date-picker
                disabled
                v-model="newTransformation.date"
                format="DD/MM/YYYY"
              ></date-picker>
            </div>
          </template>
          <!-- <template v-slot:cell(type)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">{{
              data.value
            }}</span>
            <div v-else>
              <v-select disabled v-model="newTransformation.type_objet">
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
          </template> -->
          <template v-slot:cell(object)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              <b-link
                v-if="data.value"
                :to="{
                  name: data.item.objectLink,
                  params: {
                    id: data.value.id,
                    slug: data.value.slug || 'abcde',
                  },
                }"
                >{{ data.value ? data.value.name : "-" }}
              </b-link></span
            >
            <div v-else>
              <b-form-input
                disabled
                v-model="newTransformation.type_objet"
              ></b-form-input>
            </div>
          </template>

          <template v-slot:cell(capacite)="data">
            <div v-if="!data.item.hasOwnProperty('editMode')">
              <b-link
                v-if="data.value"
                :to="{
                  name: data.value.capaciteLinkName,
                  params: {
                    id: data.value.id,
                    slug: data.value.slug || 'abcde',
                  },
                }"
              >
                {{ data.value.name }}
              </b-link>
              <span v-else>- </span>
            </div>
            <div v-else>
              <v-select
                disabled
                :options="['False', 'True']"
                v-model="newTransformation.capacite"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <div v-if="!data.item.hasOwnProperty('editMode')">
              <span
                v-if="data.value == 'Suppression'"
                class="badge badge-danger"
                >{{ data.value }}</span
              >
              <span
                v-if="data.value == 'Evolution'"
                class="badge badge-warning"
                >{{ data.value }}</span
              >
              <span
                v-if="data.value == 'Création'"
                class="badge badge-success"
                >{{ data.value }}</span
              >
              <span v-if="!data.value">{{ "-" }}</span>
            </div>

            <div v-else>
              <v-select
                disabled
                :options="['False', 'True']"
                v-model="newTransformation.type_impact"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="['edit', 'delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @transferItem="transferdata(data.item)"
              @deleteItem="deleteItem(data.index)"
            ></table-actions>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>

    <div class="row" v-show="selected === 2">
      <div class="col-12">
        <GraphicViewTransformation
          :items="tableItems"
          @showItem="showProcess = !showProcess"
          :transformationPlan="this.searchQuery.transformationPlan"
          :selectedFilter="selectedFilter"
        />
      </div>
    </div>

    <div class="row" v-show="selected === 1">
      <Gantt
        v-if="$route.params.id"
        :items="ganttItems"
        :loadingData="loadingData"
        :mode="mode"
        class="col-12"
      />
      <div
        v-else
        class="text-gray d-flex h2 text-center w-100"
        style="height: 166px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "./DropDown.vue";
import TableActions from "@/components/custom/TableActions.vue";
import GraphicViewTransformation from "./GraphicViewTransformation";
//import FrappeGantt from "@/components/custom/frappe-gantt.vue";
import Gantt from "@/pages/transformation/initiative/components/Gantt.vue";

import { mapGetters } from "vuex";
import { concat } from "lodash";
import moment from "moment";
export default {
  components: {
    DropDownCustom,
    TableActions,
    GraphicViewTransformation,
    //FrappeGantt,
    Gantt,
  },
  props: {
    disabled: Boolean,
  },
  data: () => ({
    ganttView: [
      {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },
      {
        text: "Semaine",
        id: 2,
        color: "btn-inverse-danger ",
      },
      {
        text: "Mois",
        id: 3,
        color: "btn-inverse-info ",
      },
    ],
    selectedView: {
      text: "Jour",
      id: 1,
      color: " btn-inverse-primary",
    },
    selected: 0,
    datatable: [],
    date: "",
    sortBy: "id",
    Business: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    selectedfdr: null,
    sortable: true,
    loadingData: true,
    initiatives: [],
    annee: "",
    newTransformation: {
      fdr: "",
      initiative: "",
      type_impact: "",
      objet: "",
      capacite: "",
      type_objet: "",
      date: "",
    },
    transformation: {
      tableItems: [
        {
          fdr: "Fdr 1",
          initiative: "Initiaitve 1",
          impcated_object_type: "Type",
          object: "Object 1",
          main_capacity: "Lorem",
          type: "Lorem",
          implementation_date: "12-12-2021",
        },
      ],
      tableFields: [
        { key: "fdr", label: "FDR", sortable: true },
        { key: "initiative", label: "Initiative", sortable: true },
        {
          key: "typeObject",
          label: "Type objet impacté",
          sortable: true,
        },
        {
          key: "object",
          label: "Libellé objet",
          sortable: true,
        },

        {
          key: "capacite",
          label: "Capacité principale",
          sortable: true,
        },
        {
          key: "type",
          label: "Type impact",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Date mise en oeuvre",
          sortable: true,
        },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   thStyle: { width: "150px !important", minWidth: "150px !important" },
        //   sortable: false,
        // },
      ],
    },
    isExpanded: [false, false, false, false, false],

    newTasks: [
      {
        title: "Initiative 1",
        type: "Plan actions",
        name: "Tache one two three four five six seven",
        responsable: "S. E",
        id: "1",
        start: "2021-01-01",
        end: "2021-01-05",
        progress: 10,
      },
      {
        title: "Initiative 2",
        type: "Plan actions",
        name: "Tache one two three four five six seven",
        responsable: "S. E",
        id: "2",
        start: "2021-01-05",
        end: "2021-01-10",
        progress: 20,
        // dependencies: "1"
      },
      {
        title: "Initiative 3",
        type: "Plan actions",
        name: "Tache one two three four five six seven",
        responsable: "S. E",
        id: "3",
        start: "2021-01-10",
        end: "2021-01-15",
        progress: 30,
      },
      {
        title: "Initiative 4",
        type: "Plan actions",
        name: "Tache one two three four five six seven",
        responsable: "S. E",
        id: "4",
        start: "2021-01-15",
        end: "2021-01-20",
        progress: 60,
      },
      {
        title: "Initiative 4",
        type: "Plan actions",
        name: "Tache one two three four five six seven",
        responsable: "S. E",
        id: "5",
        start: "2028-03-01",
        end: "2028-05-05",
        progress: 90,
      },
    ],
    mode: "day",
    debugEventLog: [],
    selectedFilter: 0,
    searchQuery: {
      business: 0,
      transformationPlan: "",
    },
  }),
  methods: {
    concat,

    cancelItem() {
      if (
        this.newTransformation.initiative == "" &&
        this.newTransformation.fdr == ""
      ) {
        this.newTransformation.initiatives = "";
        this.newTransformation.fdr = "";
        this.newTransformation.date = "";
        this.newTransformation.po = "";
      } else {
        this.datatable = this.datatable.filter(
          (item, indexitem) => indexitem !== this.datatable.length - 1
        );
      }
    },

    getinitaitive() {
      this.$store
        .dispatch("fdr/fetchFdr", this.newTransformation.fdr)
        .then(() => {
          this.initiatives = this.FDR.initiatives.map((data) => ({
            text: data.libelle,
            value: data.id,
          }));
        });
    },
    addinput() {
      var data = this.datatable.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.datatable.unshift({ editMode: true });
      }
    },
    onViewChange(value) {
      this.selected = value;
    },
    onFilterChange(value) {
      this.selectedFilter = value;
    },
    changeView: function (view, val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView.text = view.text;
    },
    getTheMaximumLevel(impact) {
      var capacite;

      if (impact.typeObject === "Application") {
        capacite = impact.application.capaciteSis.find(
          ({ niveau }) =>
            niveau ===
            Math.min(...impact.object.capaciteSis.map(({ niveau }) => niveau))
        );
        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "show-infosys-capacity" };
      }

      if (impact.typeObject === "Composant Technique") {
        capacite = impact.object.capacites.find(
          ({ niveau }) =>
            niveau ===
            Math.min(...impact.object.capacites.map(({ niveau }) => niveau))
        );
        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "show-technical-capacity" };
      }

      if (impact.typeObject === "Capacité Métier") {
        capacite = impact.object.parent;

        if (capacite)
          while (capacite.parent) {
            capacite = capacite.parent;
          }
        return { ...capacite, capaciteLinkName: "business-capacity" };
      }

      if (impact.typeObject === "Processus Métier") {
        capacite = impact.object.capaciteMetiers;

        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "business-capacity" };
      }

      return null;
    },
  },
  created() {
    this.$store
      .dispatch("patrimoine/fetchTransformation", this.$route.params.id)
      .then(() => {
        this.loadingData = false;
      });
    this.disabled ? (this.loadingData = false) : "";
    this.$store.dispatch("fdr/fetchAllFdrs");
    this.$store.dispatch("businessLine/fetchAllBusinessLines");
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
    ...mapGetters("patrimoine", ["TRANSFORMATION"]),
    ...mapGetters("fdr", ["FDRS"]),
    ...mapGetters("fdr", ["FDR"]),
    ...mapGetters("businessLine", ["BUSINESS_LINES"]),
    businessLine() {
      var bls = this.BUSINESS_LINES.map((data) => ({
        text: data.name,
        value: data.id,
      }));
      bls.push({
        text: "Tous",
        value: 0,
      });
      return bls;
    },

    tableItems() {
      var table = [];
      if (this.TRANSFORMATION && this.TRANSFORMATION.feuilles) {
        this.TRANSFORMATION.feuilles.map((fdr) =>
          fdr.initiatives.map((initiative) =>
            initiative.impacts.map((impact) => {
              table.push({
                ...impact,
                fdr: {
                  name: fdr.name,
                  id: fdr.id,
                  slug: fdr.slug || "abcde",
                },
                initiative: {
                  libelle: initiative.libelle,
                  id: initiative.id,
                  slug: initiative.slug || "abcde",
                },
                typeObject: impact.application
                  ? "Application"
                  : impact.composant
                  ? "Composant Technique"
                  : impact.processus
                  ? "Processus Métier"
                  : impact.capaciteMetier
                  ? "Capacité Métier"
                  : impact.concept
                  ? "Concept Métier"
                  : "-",
                object:
                  impact.application ||
                  impact.composant ||
                  impact.processus ||
                  impact.concept ||
                  impact.capaciteMetier,
                objectLink: impact.application
                  ? "show-application"
                  : impact.composant
                  ? "show-composant"
                  : impact.processus
                  ? "show-job-process"
                  : impact.concept
                  ? "show-concept"
                  : impact.capaciteMetier
                  ? "show-capacity"
                  : null,
              });
            })
          )
        );

        table = table
          .map((impact) => ({
            ...impact,
            capacite: this.getTheMaximumLevel(impact),
          }))
          .filter((impact) =>
            this.selectedFilter === 1
              ? impact.typeObject === "Application"
              : this.selectedFilter === 2
              ? impact.typeObject === "Composant Technique"
              : true
          )
          .filter((impact) =>
            this.searchQuery.transformationPlan
              ? this.searchQuery.transformationPlan === "As-Is"
                ? impact.type !== "Création"
                : true
              : true
          );
      }
      return table;
    },
    ganttItems() {
      var table = [];
      if (this.TRANSFORMATION && this.TRANSFORMATION.feuilles) {
        this.TRANSFORMATION.feuilles.map((fdr) =>
          fdr.initiatives.map((initiative) => {
            table.push({
              ...initiative,
              feuilleRoute: fdr,
            });
          })
        );
      }
      return table;
    },
    fdr() {
      var data = this.FDRS.map((fdr) => ({
        value: fdr.id,
        text: fdr.name,
      }));
      return data;
    },
    filteredViews() {
      if (this.selectedFilter === 0) return ["Tableau", "Gantt"];
      return ["Tableau", "Gantt", "Graphique"];
    },
  },
  watch: {
    selectedFilter() {
      if (this.selectedFilter === 0) {
        this.searchQuery.transformationPlan = "";
        this.selected = 0;
      }
    },
    selected() {
      if (this.selected !== 2) {
        this.searchQuery.transformationPlan = "";
      }
    },
    TRANSFORMATION() {
      // this.TRANSFORMATION.initiatives.map((data) => {
      //   var res = {
      //     ...data,
      //     id: data.id,
      //     fdr: data.feuilleRoute.name,
      //     impcated_object_type: data.type,
      //     object: "-",
      //     main_capacity: "-",
      //     type: "-",
      //   };
      //   if (data.impacts.length) {
      //     this.datatable = concat(
      //       this.datatable,
      //       data.impacts.map((impact) => ({
      //         ...data,
      //         id: data.id,
      //         fdr: data.feuilleRoute.name,
      //         impcated_object_type: data.type,
      //         object: impact.name,
      //         main_capacity: impact.isPrincipale,
      //         type: impact.type,
      //         startedAt: moment(
      //           data.feuilleRoute.createdAt,
      //           "DD/MM/YYYY"
      //         ).format("DD/MM/YYYY"),
      //         Annee: moment(data.feuilleRoute.createdAt, "DD/MM/YYYY")
      //           .format("YYYY")
      //           .toString(),
      //       }))
      //     );
      //   } else {
      //     this.datatable = concat(this.datatable, res);
      //   }
      // }),
      //   (this.loadingData = false);
    },
    annee() {
      var res = moment(this.annee, "DD/MM/YYYY").format("YYYY").toString();
      return this.datatable
        .filter((filter) =>
          this.annee ? filter.Annee == res.toLowerCase() > -1 : true
        )
        .map((data) => ({
          ...data,
        }));
    },
  },
};
</script>

<style >
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}
</style>
