var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row card m-0"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"p-2 d-flex flex-column"},[(!_vm.items.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._l((_vm.groupBy(_vm.items, 'capaciteMetiers.id')),function(item,index){return _c('div',{key:index},[_c('p',{staticClass:"mt-3 mb-2 h5"},[_vm._v(" "+_vm._s(item[0].capaciteMetiers ? item[0].capaciteMetiers.name : "N/A")+" ")]),_c('div',{staticClass:"text-white row p-2 rounded my-2",staticStyle:{"background":"#f2edf3"}},_vm._l((item),function(process,i){return _c('div',{key:'p' + i,staticClass:"col-12 col-md-3 p-1"},[_c('div',{staticClass:"p-1 bg-gradient-success rounded h-100"},[_c('p',{staticClass:"my-2 h5 text-center"},[_c('b-link',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"to":{
                    name: '',
                    params: {
                      id: process.id,
                      slug: process.slug || 'abcde',
                    },
                  }}},[_vm._v(_vm._s(process.name))])],1),_vm._l((process.sousprocessus),function(processus,appIndex){return _c('div',{key:appIndex,staticClass:"mb-2"},[_c('div',{staticClass:"h-100 p-2"},[_c('p',{staticClass:"mb-0"},[_c('b-link',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"to":{
                        name: '',
                        params: {
                          id: processus.id,
                          slug: processus.slug || 'abcde',
                        },
                      }}},[_vm._v(_vm._s(appIndex + 1)+" - "+_vm._s(processus.name || processus))])],1)])])})],2)])}),0)])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }