<template>
  <div>
    <div v-if="disabled">
      <div class="d-flex">
        <span class="h4 text-secondary mr-auto my-auto">Liste des FdR </span>

        <div class="d-flex justify-content-end">
          <b-button
            disabled
            variant="secondary"
            class="my-auto align-btn-table-Fdr-lg"
            style="
              background-color: #007bffff !important;
              border-color: #007bffff !important;
            "
            ><i class="mdi mdi-plus"></i> Ajouter un FdR
          </b-button>
        </div>
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
    </div>
    <div v-else>
      <div class="d-flex">
        <div class="my-auto d-flex mr-auto">
          <span class="h4 text-secondary mr-auto my-auto">Liste des FdR </span>
          <DropDownCustom
            class="my-auto ml-1"
            :items="['Tableau', 'Gantt']"
            @changeValue="onValueChange"
          />
        </div>
        <div class="d-flex justify-content-end">
          <CustomAddButton @click="showAddFdr = true" text="Ajouter FdR" />
          <CustomAddButton
            class="ml-1"
            @click="
              $router.push({
                name: 'add-fdr',
                params: {
                  fromPatrimoine: true,
                  patrimoineId: $route.params.id,
                  patrimoineSlug: $route.params.slug,
                },
              })
            "
            text="Créer une FdR"
          />
        </div>
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
      <div class="col-12 d-flex flex-wrap p-0 mb-3">
        <div class="pr-2" style="width: 200px">
          <b-form-input
            v-model="searchQuery.name"
            class="mr-2 w-100"
            placeholder="Libellé"
          />
        </div>
        <div class="pr-2" style="width: 200px">
          <v-select
            v-model="searchQuery.responsable"
            class="mr-2 w-100"
            :options="responsables"
            :reduce="(responsable) => responsable.value"
            placeholder="Architecte technique"
            label="text"
          >
            <template #no-options> Liste vide </template>
          </v-select>
        </div>
        <div class="pr-2" style="width: 200px">
          <v-select
            v-model="searchQuery.status"
            class="mr-2"
            :options="status"
            placeholder="Statut"
          >
            <template #no-options> Liste vide </template></v-select
          >
        </div>
      </div>
    </div>

    <div class="row">
      <div v-if="!showGantt" class="col-12">
        <b-table
          :items="filteredFdrs"
          :fields="fdr.tableFields"
          :busy="loadingData"
          :sort-by.sync="sortBy"
          responsive
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
          bordered
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(progress)="data">
            <b-progress
              v-if="!data.item.hasOwnProperty('editMode')"
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd !important"
            >
              <b-progress-bar
                :style="`background-color: ${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
            <div v-else>
              <b-progress
                max="100"
                show-progress
                height="15px"
                style="background-color: #cacccd !important"
              >
                <b-progress-bar :value="0" :label="`${0}%`"></b-progress-bar>
              </b-progress>
            </div>
          </template>
          <template v-slot:cell(responsable)="data">
            <TableResponsable
              v-if="!data.item.hasOwnProperty('editMode')"
              :responsable="data.value"
            >
            </TableResponsable>
            <div v-else style="width: 170px !important">
              <v-select
                append-to-body
                label="text"
                :options="responsables"
                :reduce="(responsable) => responsable.value"
                placeholder="Responsable"
                v-model="newFdr.responsable"
                style="padding: 0 !important"
                :class="{
                  'is-invalid form-control p-0':
                    $v.newFdr.responsable.$error &&
                    $v.newFdr.responsable.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFdr.responsable.$dirty"
              >
                {{ !$v.newFdr.responsable.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>
          <template v-slot:cell(startedAt)="data">
            <span
              v-if="!data.item.hasOwnProperty('editMode')"
              class="text-center px-2 py-1 rounded"
              >{{
                moment(data.item.startedAt, "DD/MM/YYYY").format("DD/MM/YYYY")
              }}</span
            >
            <div class="row col-12 mx-auto p-0" v-else>
              <date-picker
                style="height: 44.22px; min-width: max-content"
                :value="datePickerFormat(newFdr.startedAt)"
                v-model="newFdr.startedAt"
                placeholder="Date Fin"
                format="DD/MM/YYYY"
                :input-attr="{
                  class: {
                    'is-invalid':
                      $v.newFdr.startedAt.$error && $v.newFdr.startedAt.$dirty,
                    ' form-control': true,
                  },
                }"
              />
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFdr.startedAt.$dirty"
              >
                {{ !$v.newFdr.startedAt.required ? "Champ obligatoire" : "" }}
              </span>
              <!--  -->
            </div>
          </template>
          <template v-slot:cell(deadline)="data">
            <span
              v-if="!data.item.hasOwnProperty('editMode')"
              class="text-white text-center px-2 py-1 rounded"
              :style="{
                backgroundColor: dateBackgroundColor(data.value.end_date),
              }"
              >{{
                moment(data.item.endingAt, "DD/MM/YYYY").format("DD/MM/YYYY")
              }}</span
            >
            <div class="row col-12 mx-auto p-0" v-else>
              <date-picker
                style="height: 44.22px; min-width: max-content"
                :value="datePickerFormat(newFdr.datedefin)"
                v-model="newFdr.datedefin"
                placeholder="Date Fin"
                format="DD/MM/YYYY"
                :input-attr="{
                  class: {
                    'is-invalid':
                      $v.newFdr.datedefin.$error && $v.newFdr.datedefin.$dirty,
                    ' form-control': true,
                  },
                }"
              />
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFdr.datedefin.$dirty"
              >
                {{
                  !$v.newFdr.datedefin.required
                    ? "Champ obligatoire"
                    : $v.newFdr.datedefin.required && $v.newFdr.datedefin.$error
                    ? "La date de fin ne doit pas être antérieur à la date de début"
                    : ""
                }}
              </span>
              <!--  -->
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              @click="
                $router.push({
                  name: 'show-fdr',
                  params: {
                    id: data.item.id,
                    slug: data.item.slug || 'abcde',
                  },
                })
              "
              v-if="!data.item.hasOwnProperty('editMode')"
              >{{ data.value }}
            </b-link>
            <div v-else>
              <b-form-input
                type="text"
                v-model="newFdr.name"
                placeholder="Nom"
                :class="{
                  'is-invalid': $v.newFdr.name.$error && $v.newFdr.name.$dirty,
                }"
                style="min-width: max-content"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFdr.name.$dirty"
              >
                {{ !$v.newFdr.name.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>

          <template v-slot:cell(type)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else>
              <v-select
                append-to-body
                label="value"
                :options="type"
                type="text"
                v-model="newFdr.type"
                placeholder="Type"
                :class="{
                  'is-invalid': $v.newFdr.type.$error && $v.newFdr.type.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFdr.type.$dirty"
              >
                {{ !$v.newFdr.type.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>

          <template v-slot:cell(status)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}
            </span>
            <div v-else style="width: 170px !important">
              <v-select
                append-to-body
                label="value"
                :options="status"
                placeholder="status"
                v-model="newFdr.statut"
                :class="{
                  'is-invalid':
                    $v.newFdr.statut.$error && $v.newFdr.statut.$dirty,
                }"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newFdr.statut.$dirty"
              >
                {{ !$v.newFdr.statut.required ? "Champ obligatoire" : "" }}
              </span>
            </div>
          </template>

          <template v-slot:cell(initiatives)="data">
            <div
              class="text-center"
              v-if="!data.item.hasOwnProperty('editMode')"
            >
              <b-badge
                v-if="data.value.length"
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                @click="showInitiativesFunction(data.item.id)"
                >{{ data.value.length }}
              </b-badge>
              <b-badge
                v-else
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                >{{ data.value.length }}
              </b-badge>
            </div>
            <div class="text-center" v-else style="width: 170px !important">
              <b-badge
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                pill
                >{{ data.value.length }}
              </b-badge>
            </div>
          </template>

          <template v-slot:cell(actions)="data">
            <table-actions
              :actions="['edit', 'delete']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @cancelItem="cancelItem()"
              @confirmItem="addFdr(data.item)"
              @editItem="editItem(data.item)"
              @deleteItem="deleteFdr(data.item.id)"
            />
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
      <div class="col-12" v-else>
        <Gantt
          :items="filteredFdrs"
          :loadingData="loadingData"
          :mode="mode"
          @showInitiatives="showInitiativesFunction"
        />
      </div>
    </div>

    <div v-if="showInitiatives" to="modals-xyz-548">
      <modal @close="showInitiatives = !showInitiatives">
        <initiativeList :id="selectedfdr" />
      </modal>
    </div>
    <div v-if="showInitiativesselect" to="modals-xyz-548">
      <modal @close="showInitiativesselect = !showInitiativesselect">
        <initiativeselect
          :selected="options"
          :libelle="libelle"
          @confirminitiative="confirminitiative"
          @close="showInitiativesselect = !showInitiativesselect"
        ></initiativeselect>
      </modal>
    </div>
    <div v-if="showAddFdr" to="modals-xyz-548">
      <modal @close="showAddFdr = !showAddFdr">
        <AddFdr
          :patrimoine="FDR"
          @close="showAddFdr = !showAddFdr"
          :libelle="libelle"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Modal from "@/components/custom/Modal.vue";
import initiativeList from "./InitiativeList";
import initiativeselect from "./Initiativeselect.vue";
import Gantt from "@/pages/transformation/fdr/components/Gantt.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import TableResponsable from "@/components/custom/TableResponsable";
import { minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { concat } from "lodash";
import CustomAddButton from "@/components/custom/CustomAddButton.vue";
import DropDownCustom from "@/components/custom/DropDown.vue";
import AddFdr from "./AddFdr.vue";
import { USER_URL, PATRIMOINE_URL } from "@/helpers/constURL";

export default {
  components: {
    TableActions,
    initiativeList,
    Modal,
    TableResponsable,
    initiativeselect,
    CustomAddButton,
    DropDownCustom,
    AddFdr,
    Gantt,
  },
  props: {
    disabled: Boolean,
    libelle: String,
  },
  data: () => ({
    mode: "day",

    showGantt: 0,
    sortBy: "id",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    selectedfdr: null,
    sortable: true,
    loadingData: true,
    showInitiatives: false,
    options: null,
    showInitiativesselect: false,
    dataFdr: [],
    listInititives: [],
    newFdr: {
      name: "",
      initiative: [],
      type: "",
      responsable: "",
      statut: "",
      datededebut: "",
      datedefin: "",
      startedAt: "",
      // organisation: "",
    },
    fdr: {
      tableFields: [
        { key: "name", label: "FdR", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        {
          key: "initiatives",
          label: "Nb Initiatives",
          sortable: true,
        },
        { key: "startedAt", label: "Date debut", sortable: true },
        { key: "deadline", label: "Date fin", sortable: true },
        { key: "progress", label: "Avancement", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
      tableItems: [],
    },
    showAddFdr: false,
    searchQuery: {
      name: "",
      status: "",
      responsable: null,
    },
  }),
  created() {
    this.$store
      .dispatch("patrimoine/fetchFdr", this.$route.params.id)
      .then(() => {
        this.loadingData = false;
      });
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("initiative/fetchAllInitiatives");
    this.$store.dispatch("type/fetchFdrTypes");
    this.$store.dispatch("status/fetchFdrStatus");
    if (this.disabled) this.loadingData = false;
  },
  computed: {
    rows() {
      return this.dataFdr ? this.dataFdr.length : 0;
    },
    ...mapGetters("patrimoine", ["FDRS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("initiative", ["INITIATIVES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("type", ["FDR_TYPES"]),
    ...mapGetters("status", ["FDR_STATUS"]),
    ...mapGetters("fdr", ["FDR"]),

    filteredFdrs() {
      return this.dataFdr.filter(
        (feuille) =>
          (this.searchQuery.name
            ? feuille.name
                .toLowerCase()
                .includes(this.searchQuery.name.toLowerCase())
            : true) &&
          (this.searchQuery.status
            ? feuille.status === this.searchQuery.status
            : true) &&
          (this.searchQuery.responsable
            ? feuille.responsable.id === this.searchQuery.responsable
            : true)
      );
    },
    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        value: responsable.id,
        text: `${responsable.firstname} ${responsable.lastname}`,
      }));
      return data;
    },
    organisation() {
      var data = this.ORGANISATIONS.map((organisation) => ({
        id: organisation.id,
        value: `${organisation.libelle} `,
      }));
      return data;
    },
    initiative() {
      var data = this.INITIATIVES.map((initiative) => ({
        value: initiative["id"],
        text: initiative.libelle,
      }));
      return data;
    },
    type() {
      var data = this.FDR_TYPES;
      return data;
    },
    status() {
      var data = this.FDR_STATUS;
      return data;
    },
  },
  validations: {
    newFdr: {
      name: {
        required,
        minLength: minLength(5),
      },
      type: {
        required,
      },
      responsable: {
        required,
      },
      statut: {
        required,
      },

      datedefin: {
        required,
        minValue(val) {
          return this.newFdr.startedAt
            ? moment(val, "DD/MM/YYYY").diff(
                moment(this.newFdr.startedAt, "DD/MM/YYYY")
              ) +
                1 >
                0
            : true;
        },
      },
      startedAt: {
        required,
      },
    },
  },
  watch: {
    FDRS() {
      this.dataFdr = this.FDRS
        ? this.FDRS.feuilles.map((item) => ({
            ...item,
            deadline: {
              start_date: item.startedAt,
              end_date: item.endingAt,
            },
            actions: ["edit", "delete"],

            progress: item.avancement,
          }))
        : [];
    },
  },
  methods: {
    datePickerFormat,
    confirminitiative(data) {
      this.listInititives = data;
    },
    cancelItem() {
      if (
        this.newFdr.name == "" &&
        this.newFdr.type == "" &&
        this.newFdr.statut == "" &&
        this.newFdr.responsable == "" &&
        this.newFdr.datedefin == "" &&
        this.newFdr.startedAt == "" &&
        this.newFdr.organisation == ""
      ) {
        var data = this.dataFdr.find(
          (contributer) => contributer.Modify == true
        );
        if (data == null) {
          this.dataFdr = this.dataFdr.filter(
            (item, indexitem) => indexitem !== this.dataFdr.length - 1
          );
        } else {
          this.$store.dispatch("patrimoine/fetchFdr", this.$route.params.id);
        }
      } else {
        this.newFdr.name = "";
        this.newFdr.type = "";
        this.newFdr.statut = "";
        this.newFdr.responsable = "";
        this.listInititives = [];
        this.newFdr.datedefin = "";
        this.newFdr.startedAt = "";
        this.newFdr.organisation = "";
      }
    },
    deleteFdr: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.dataFdr = this.dataFdr.filter((item) => item.id !== id);

          this.$store
            .dispatch("fdr/updateFdr", {
              id: id,
              patrimoine: null,
            })
            .then(() => {
              this.$store
                .dispatch("patrimoine/fetchFdr", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
    dateBackgroundColor: function (date) {
      // "#38b000" : "#d20f35";
      moment(date);
      var diff = parseInt(
        moment
          .duration(
            moment(date, "DD/MM/YYYY").diff(moment(new Date(), "DD/MM/YYYY"))
          )
          .asDays()
      );
      return diff < 0 ? "#d20f35" : diff > 4 ? "#38b000" : "#ffd60a";
    },
    additem() {
      var data = this.dataFdr.find(
        (contributer) => contributer.editMode == true
      );
      if (data == null) {
        this.currentPage = 1;
        this.dataFdr.push({ editMode: true });
      }
    },
    editItem(item) {
      this.$store
        .dispatch("patrimoine/fetchFdr", this.$route.params.id)
        .then(() => {
          this.listInititives = item.initiatives;
          this.newFdr.name = item.name;
          this.newFdr.type = item.type;
          this.newFdr.initiative = item.initiatives
            ? item.initiatives.map((cov) => ({
                value: cov["@id"],
                text: cov.libelle,
              }))
            : "";
          this.newFdr.datedefin = datePickerFormat(item.endingAt);
          this.newFdr.startedAt = datePickerFormat(item.startedAt);
          this.newFdr.statut = item.status;
          this.newFdr.responsable = item.responsable.id;
          this.dataFdr = this.dataFdr.map((contributer) =>
            contributer.id === item.id
              ? { ...contributer, editMode: true, Modify: true }
              : contributer
          );
        });
    },
    showInitiativesFunction(id) {
      this.showInitiatives = !this.showInitiatives;
      this.selectedfdr = id;
    },
    Initiativesselect(data) {
      this.showInitiativesselect = !this.showInitiativesselect;
      var test = this.dataFdr.find((contributer) => contributer.Modify == true);
      if (test) {
        var push = data.initiatives
          ? data.initiatives.map((data) => data["@id"])
          : [];
        this.options = push;
      } else {
        var pushdata = this.options
          ? concat(
              this.listInititives,
              data.initiatives
                ? data.initiatives.map((data) => data["@id"])
                : []
            )
          : data.initiatives
          ? data.initiatives.map((data) => data["@id"])
          : [];
        this.options = pushdata;
      }
    },
    addFdr(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var fdr = {
          id: item.id,
          name: this.newFdr.name,
          type: this.newFdr.type,
          status: this.newFdr.statut,
          responsable: `/api/${USER_URL}/${this.newFdr.responsable}`,
          endingAt: moment(this.newFdr.datedefin).format("DD/MM/YYYY"),
          startedAt: moment(this.newFdr.startedAt).format("DD/MM/YYYY"),
          budget: 0.0,
          description: " ",
          patrimoine: `/api/${PATRIMOINE_URL}/${this.$route.params.id}`,
        };
        if (!fdr.id) {
          this.$store.dispatch("fdr/createFdr", fdr).then(() => {
            Swal.fire("La feuille de route est bien créée !", "", "success");
            this.$store
              .dispatch("patrimoine/fetchFdr", this.$route.params.id)
              .then(() => {
                this.$store
                  .dispatch("financeFeuille/updateFinanceFeuille", {
                    id: this.FDR.finance.id,
                    responsable: `/api/${USER_URL}/${this.newFdr.responsable}`,
                  })
                  .then(() => {
                    this.newFdr.name = "";
                    this.newFdr.type = "";
                    this.newFdr.statut = "";
                    this.newFdr.responsable = "";
                    this.listInititives = [];
                    this.newFdr.datedefin = "";
                    this.newFdr.startedAt = "";
                    this.newFdr.organisation = "";
                  });
              });
          });
        } else {
          this.$store.dispatch("fdr/updateFdr", fdr).then(() => {
            this.$store
              .dispatch("patrimoine/fetchFdr", this.$route.params.id)
              .then(() => {
                Swal.fire(
                  "La feuille de route est mise à jour !",
                  "",
                  "success"
                );
                this.newFdr.name = "";
                this.newFdr.type = "";
                this.newFdr.statut = "";
                this.newFdr.responsable = "";
                this.listInititives = [];
                this.newFdr.datedefin = "";
                this.newFdr.startedAt = "";
                this.newFdr.organisation = "";
              });
          });
        }
      }
    },
    onValueChange(value) {
      this.showGantt = value;
    },
  },
};
</script>

<style>
.table_responsive {
  overflow: scroll !important;
}

.multiselect--active {
  z-index: 20;
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
}

.v-toolbar {
  z-index: 10;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: unset;
  z-index: 20;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.custom {
  width: 80% !important;
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
