<template>
  <div style="margin-top: -50px">
    <p class="text-secondary my-auto mr-auto">
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Patrimoine :
      </span>
      <span style="font-size: 22px">{{ libelle }}</span>
    </p>
    <br />
    <p class="h3">Ajouter application</p>

    <hr />
    <div class="d-flex align-items-center pb-2 pt-0">
      <span class="d-inline-block w-75 h4 mb-0">Recherche application:</span>
      <b-form-input placeholder="Application" v-model="searchQuery.name" />
      <b-button
        class="ml-1"
        @click="addApplication"
        :disabled="selectedRole === null"
      >
        Confirmer
      </b-button>
    </div>
    <!-- {{ selected ? selected.id : "-" }} -->
    <b-table
      :fields="fields"
      :items="applicationsList"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loadingData"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <!-- <template #cell(selection)="{ rowSelected }">
        <div class="d-flex justify-content-center">
          <b-form-checkbox disabled :checked="rowSelected"></b-form-checkbox>
          <b-form-radio
          :value="rowSelected"
            :checked="rowSelected"
          ></b-form-radio>
          {{rowSelected}}
        </div>
      </template> -->
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedRole"
            :value="data.item.id"
          ></b-form-radio>
        </div>
      </template>
      <template #cell(patrimoine)="data">
        <b-link
          v-if="data.value"
          :to="{
            name: 'show-patrimony',
            params: {
              id: data.value.id,
              slug: data.value.slug || 'abcde',
            },
          }"
        >
          {{ data.value.name }}</b-link
        >
        <span v-else>-</span>
      </template>
      <template #cell(capaciteSis)="data">
        <b-link
          v-if="capacitePrincipale(data.value)"
          :to="{
            name: 'show-infosys-capacity',
            params: {
              id: capacitePrincipale(data.value).id,
              slug: capacitePrincipale(data.value).slug || 'abcde',
            },
          }"
        >
          {{ capacitePrincipale(data.value).name }}</b-link
        >
        <span v-else>-</span>
      </template>
      <template #cell(name)="data">
        <b-link
          :to="{
            name: 'show-application',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.value }}</b-link
        >
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedRole: null,
      selectedUser: null,
      loadingData: true,
      selectrole: "",
      searchQuery: {
        name: "",
      },
      selected: null,
      fields: [
        { key: "name", label: "Libellé" },
        { key: "status", label: "Statut" },
        { key: "capaciteSis", label: "Capacité SI principale" },
        { key: "patrimoine", label: "Patrimoine" },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
      // selectedRole: null,
    };
  },
  validations: {
    selectrole: {
      required,
    },
  },
  created() {
    this.$store.dispatch("application/fetchAllApplications").then(() => {
      this.loadingData = false;
    });
  },
  methods: {
    capacitePrincipale(capacitesSis) {
      var capacitePrincipale;
      if (capacitesSis.length) {
        capacitePrincipale = capacitesSis.find((capacite) => capacite.parent)
          ? capacitesSis.find((capacite) => capacite.parent).parent
          : capacitesSis[0];
      }
      return capacitePrincipale;
    },
    addApplication() {
      if (this.selectedRole)
        this.$store
          .dispatch("application/updateApplication", {
            id: this.selectedRole,
            patrimoine: this.patrimoine["@id"],
          })
          .then(() => {
            this.$store
              .dispatch("patrimoine/fetchApplications", this.$route.params.id)
              .then(() => {
                this.$emit("close");
                Swal.fire("L'application est bien créée!", "", "success");
              });
          });
    },
    onRowSelected(item) {
      this.selected = item[0];
    },
  },
  computed: {
    ...mapGetters("processusMetier", ["PROCESSUS_METIERS"]),
    ...mapGetters("application", ["APPLICATIONS"]),
    rows() {
      return this.applicationsList.length;
    },
    applicationsList() {
      return this.APPLICATIONS.filter(
        (application) =>
          !this.patrimoine.applications.find(
            (pApplication) => pApplication.id === application.id
          ) && application.name.includes(this.searchQuery.name)
      );
    },
  },
  props: {
    patrimoine: Object,
    libelle: String,
  },
};
</script>

<style></style>
