<template>
  <div v-if="disabled == true">
    <div class="d-flex mb-2">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des référents
      </span>

      <div class="d-flex justify-content-end">
        <b-button
          style="
            background-color: #007bffff !important;
            border-color: #007bffff !important;
          "
          class="mb-2 align-btn-table-Fdr-lg"
          disabled
          @click="showAddRole = !showAddRole"
          variant="secondary"
          ><i class="mdi mdi-plus"></i> Ajouter un rôle</b-button
        >
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
      <br />
    </div>
    <div align="center">
      <div class="text-gray d-flex h2 text-center w-100" style="height: 140px">
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucun référent</span>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="d-flex mb-2">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des référents
      </span>

      <div class="d-flex justify-content-end">
        <b-button
          style="
            background-color: #007bffff !important;
            border-color: #007bffff !important;
          "
          @click="showAddRole = !showAddRole"
          variant="secondary"
          ><i class="mdi mdi-plus"></i> Ajouter un rôle</b-button
        >
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <br />
    <div v-if="!items || !items.referents.length" align="center">
      <div
        class="te||xt-gray d-flex h2 text-center w-100"
        style="height: 140px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucun référent</span>
      </div>
    </div>
    <div class="p-0 mx-auto row col-12">
      <CustomReferentCarde
        :key="index"
        v-for="(item, index) in items ? items.referents : []"
        :role="item.role"
        :user="item.user"
        :index="index"
        @setUserRole="setUserRole"
        @deleteUser="deleteUser"
      />
    </div>
    <div v-if="showAddCollaborator" to="modals-xyz-548">
      <Modal @close="showAddCollaborator = !showAddCollaborator">
        <AddCollaborator @cofirmUser="confirmUser" :libelle="items.name" />
      </Modal>
    </div>

    <div v-if="showAddRole" to="modals-xyz-548">
      <modal @close="showAddRole = !showAddRole">
        <AddRole @cofirmRole="addrole" :libelle="items.name" />
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/custom/Modal.vue";
import CustomReferentCarde from "@/components/custom/CustomReferentCarde.vue";
import AddCollaborator from "./AddCollaborator.vue";
import AddRole from "./AddRole.vue";
import Swal from "sweetalert2";
export default {
  components: { AddCollaborator, Modal, AddRole, CustomReferentCarde },
  data: () => ({
    showAddRole: false,
    showAddCollaborator: false,
    selectedReferent: null,
    role: null,
  }),
  props: {
    disabled: Boolean,
    items: [Object, Array],
  },
  methods: {
    setUserRole(index, selectedRole) {
      this.selectedReferent = index;

      this.role = selectedRole;
      this.showAddCollaborator = !this.showAddCollaborator;
    },
    confirmUser(userId) {
      var list = null;

      list = {
        ref: `Référence ${this.$route.params.id}`,
        role: this.role,
        user: `/api/users/${userId}`,
        patrimoine: `/api/referentiel/patrimoines/${this.$route.params.id}`,
      };

      this.$emit("cofirmRole", list);
      Swal.fire("Le référent est bien créé !", "", "success");

      this.showAddCollaborator = !this.showAddCollaborator;
    },
    deleteUser(index) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$emit("deleteUser", this.items.referents[index].id);
          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
    addrole(role) {
      this.items.referents.push({
        role: role,
        user: null,
      });
      this.showAddRole = !this.showAddRole;
    },
  },
};
</script>

<style></style>
