<template>
  <div class="row card m-0">
    <div class="col-12 p-0">
      <div class="p-2 d-flex flex-column">
        <!--  -->
        <div
          v-if="
            selectedFilter === 1 &&
            applicationsList.length &&
            transformationPlan === 'To Be'
          "
        >
          <div
            v-for="(transformation, index) in groupBy(
              applicationsList,
              'capacite.id'
            )"
            :key="index"
          >
            <p class="text-secondary pb-0">
              {{
                transformation[0].capacite && transformation[0].capacite.name
                  ? transformation[0].capacite.name
                  : "N/A"
              }}
            </p>
            <div
              class="
                row
                rounded
                bg-light
                shadow-sm
                border
                col-12
                mx-auto
                mb-3
                p-0
              "
            >
              <div
                v-for="(tran, i) in transformation"
                :key="'tran' + i"
                class="col-12 col-md-2"
              >
                <div class="py-2">
                  <div>
                    <!-- {{ tran.name }} -->
                    <div class="text-gray text-center w-100" v-if="!tran">
                      <i class="mdi mdi-information-outline my-auto"></i>
                      section vide
                    </div>
                    <b-link
                      v-else
                      :to="{
                        name: 'show-application',
                        params: {
                          id: tran.id,
                          slug: tran.slug || 'abcde',
                        },
                      }"
                      class="
                        rounded
                        px-3
                        py-2
                        d-inline-block
                        text-white text-decoration-none
                      "
                      style="cursor: pointer; background: gray"
                    >
                      {{ tran.name }}
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!transformation.length"
              class="text-gray d-flex h2 text-center w-100"
              style="height: 166px"
            >
              <i
                class="mdi my-auto ml-auto mdi-information-outline my-auto"
              ></i>
              <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
            </div>
          </div>
        </div>
        <!--  -->
        <div
          v-if="
            selectedFilter === 2 &&
            composantsList.length &&
            transformationPlan === 'To Be'
          "
        >
          <div
            v-for="(transformation, index) in groupBy(
              composantsList,
              'capacite.id'
            )"
            :key="index"
          >
            <p class="text-secondary pb-0">
              {{
                transformation[0].capacite && transformation[0].capacite.name
                  ? transformation[0].capacite.name
                  : "N/A"
              }}
            </p>
            <div
              class="
                row
                rounded
                bg-light
                shadow-sm
                border
                col-12
                mx-auto
                mb-3
                p-0
              "
            >
              <div
                v-for="(tran, i) in transformation"
                :key="'tran' + i"
                class="col-12 col-md-2"
              >
                <div class="py-2">
                  <div>
                    <div class="text-gray text-center w-100" v-if="!tran">
                      <i class="mdi mdi-information-outline my-auto"></i>
                      section vide
                    </div>
                    <b-link
                      v-else
                      :to="{
                        name: 'show-composant',
                        params: {
                          id: tran.id,
                          slug: tran.slug || 'abcde',
                        },
                      }"
                      class="
                        rounded
                        px-3
                        py-2
                        d-inline-block
                        text-white text-decoration-none
                      "
                      style="cursor: pointer; background: gray"
                    >
                      {{ tran.name }}
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!transformation.length"
              class="text-gray d-flex h2 text-center w-100"
              style="height: 166px"
            >
              <i
                class="mdi my-auto ml-auto mdi-information-outline my-auto"
              ></i>
              <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
            </div>
          </div>
        </div>
        <!--  -->
        <div
          v-if="!items.length"
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
        <!--  -->
        <div
          v-else-if="
            items[0].typeObject === 'Application' &&
            transformationPlan !== 'To Be'
          "
          v-for="(transformation, index) in groupBy(graphItems, 'capacite.id')"
          :key="index"
        >
          <p class="text-secondary pb-0">
            {{
              transformation[0].capacite
                ? transformation[0].capacite.name
                : "N/A"
            }}
          </p>
          <div
            class="
              row
              rounded
              bg-light
              shadow-sm
              border
              col-12
              mx-auto
              mb-3
              p-0
            "
          >
            <div
              v-for="(tran, i) in transformation"
              :key="'tran' + i"
              class="col-12 col-md-2"
            >
              <div class="py-2">
                <div>
                  <b-link
                    :to="{
                      name: 'show-application',
                      params: {
                        id: tran.application.id,
                        slug: tran.application.slug || 'abcde',
                      },
                    }"
                    :class="
                      'rounded px-3  py-2  d-inline-block text-white text-decoration-none ' +
                      setBgColor(tran.type)
                    "
                    style="cursor: pointer"
                  >
                    {{ tran.application.name }}
                  </b-link>
                  <div
                    class="text-gray text-center w-100"
                    v-if="!tran.application"
                  >
                    <i class="mdi mdi-information-outline my-auto"></i>
                    section vide
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!transformation.length"
            class="text-gray d-flex h2 text-center w-100"
            style="height: 166px"
          >
            <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
            <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
          </div>
        </div>
        <!--  -->
        <div
          v-else-if="
            items[0].typeObject === 'Composant Technique' &&
            transformationPlan !== 'To Be'
          "
          v-for="(transformation, i) in groupBy(graphItems, 'capacite.id')"
          :key="i"
          class="w-100 mb-3"
        >
          <p class="text-secondary pb-0">
            {{
              transformation[0].capacite
                ? transformation[0].capacite.name
                : "N/A"
            }}
          </p>
          <div
            class="
              row
              rounded
              bg-light
              shadow-sm
              border
              col-12
              mx-auto
              mb-3
              p-0
            "
          >
            <div
              v-for="(tran, i) in transformation"
              :key="'tran' + i"
              class="col-12 col-md-2"
            >
              <div class="py-2">
                <div>
                  <div
                    class="text-gray text-center w-100"
                    v-if="!tran.composant"
                  >
                    <i class="mdi mdi-information-outline my-auto"></i>
                    section vide
                  </div>
                  <b-link
                    v-else
                    :to="{
                      name: 'show-composant',
                      params: {
                        id: tran.composant ? tran.composant.id : 0,
                        slug: tran.composant ? tran.composant.slug : 'abcde',
                      },
                    }"
                    :class="
                      'rounded px-3  py-2  d-inline-block text-white text-decoration-none ' +
                      setBgColor(tran.type)
                    "
                    style="cursor: pointer"
                  >
                    {{ tran.composant.name }}
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    items: Array,
    transformationPlan: {
      type: String,
      default: "",
    },
    selectedFilter: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    groupBy,
    setBgColor(status) {
      switch (status) {
        case "Suppression":
          return "bg-danger";
        case "Evolution":
          return "bg-warning";
        case "N/A":
          return "bg-gray";
        case "Création":
          return "bg-success";
        default:
          return "bg-secondary";
      }
    },
    getTheMaximumLevel(object, type) {
      var capacite;

      if (type === "Application") {
        capacite = object.capaciteSis.find(({ niveau }) =>
          niveau
            ? niveau ===
              Math.min(...object.capaciteSis.map(({ niveau }) => niveau))
            : true
        );
        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "show-infosys-capacity" };
      }

      if (type === "Composant Technique") {
        capacite = object.capacites.find(
          ({ niveau }) =>
            niveau === Math.min(...object.capacites.map(({ niveau }) => niveau))
        );
        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "show-technical-capacity" };
      }

      if (type === "Capacité Métier") {
        capacite = object.parent;

        if (capacite)
          while (capacite.parent) {
            capacite = capacite.parent;
          }
        return { ...capacite, capaciteLinkName: "business-capacity" };
      }

      if (type === "Processus Métier") {
        capacite = object.capaciteMetiers;

        if (capacite && capacite.parent) capacite = capacite.parent;

        return { ...capacite, capaciteLinkName: "business-capacity" };
      }

      return null;
    },
  },
  computed: {
    // ...mapGetters("application", ["CAPACITES"]),
    ...mapGetters("patrimoine", ["APPLICATION"]),
    ...mapGetters("patrimoine", ["COMPOSANT"]),

    graphItems() {
      return this.items;
    },
    applicationsList() {
      let capacite = null;
      return this.APPLICATION && this.APPLICATION.applications
        ? this.APPLICATION.applications.map((application) => {
            capacite = this.getTheMaximumLevel(application, "Application");
            return {
              ...application,
              capacite: capacite,
            };
          })
        : [];
    },
    composantsList() {
      let capacite = null;
      return this.COMPOSANT && this.COMPOSANT.composants
        ? this.COMPOSANT.composants.map((composant) => {
            capacite = this.getTheMaximumLevel(
              composant,
              "Composant Technique"
            );
            return {
              ...composant,
              capacite: capacite,
            };
          })
        : [];
      // return this.COMPOSANT.composants;
    },
  },
};
</script>

<style></style>
