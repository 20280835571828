<template>
  <section class="tables" style="margin-top: -80px">
    <div class="row p-4">
      <div class="col-10 p-0">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light">Processus : </span>
          <span style="font-size: 22px">{{ items.name }}</span>
        </p>
      </div>
    </div>

    <div class="row p-4" style="margin-top: -40px">
      <div class="col-12 p-0">
        <h4 class="card-title mt-4 mb-1">Liste des applications :</h4>
        <hr />
      </div>
    </div>
    <div class="row" style="margin-top: -20px">
      <div class="col-12">
        <b-table
          responsive
          :items="items.applications"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          bordered
          striped
        >
          <template v-slot:cell(id)="data">
            <span style="text-decoration: underline; color: blue; cursor: pointer">{{
              "SA" + data.value
            }}</span>
          </template>
          <template #cell(capaciteSis)="data">
            <div class="text-center">
              <b-link
                v-if="capacitePrincipale(data.value)"
                :to="{
                  name: 'show-infosys-capacity',
                  params: {
                    id: capacitePrincipale(data.value).id,
                    slug: capacitePrincipale(data.value).slug || 'abcde',
                  },
                }"
              >
                {{ capacitePrincipale(data.value).name }}</b-link
              >
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              :to="{
                name: 'show-application',
                params: {
                  slug: data.item.slug ? data.item.slug : '620f6d5321c35',
                  id: data.item.id,
                },
              }"
              >{{ data.value }}</b-link
            >
          </template>
          <template v-slot:cell(taux)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :style="`background:${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };font-size:8px;`"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(po)="data">
            <TableResponsable :responsable="data.value" />
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        ></b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableResponsable },
  props: {
    items: Array,
  },
  computed: {
    rows() {
      return this.items.applications.length;
    },
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableItems: [],
      tableFields: [
        { key: "id", label: "Réf. SA", sortable: true },
        { key: "name", label: "Application", sortable: true },
        {
          key: "status",
          label: "Statut",
          sortable: true,
        },
        {
          key: "capaciteSis",
          label: "Capacité principale",
          sortable: true,
        },
        {
          key: "po",
          label: "PO",
          sortable: true,
        },
        {
          key: "taux",
          label: "Taux de remplissage",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    capacitePrincipale(capacitesSis) {
      var capacitePrincipale;
      if (capacitesSis.length) {
        capacitePrincipale = capacitesSis.find((capacite) => capacite.parent)
          ? capacitesSis.find((capacite) => capacite.parent).parent
          : capacitesSis[0];
      }
      return capacitePrincipale;
    },
  },
};
</script>
