var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row m-0 p-0 table_responsivo"},[_c('div',{staticClass:"col-sm-3 table m-0 p-0",attrs:{"id":"gantt-table"}},[_vm._m(0),_vm._l((_vm.ganttData),function(item,index){return _c('div',{key:index + '1245',staticClass:"sd_menu_row d-flex align-items-center",style:({
          backgroundColor: index % 2 == 0 ? 'white' : '#f5f5f5',
        })},[_c('p',{staticClass:"fl_2 px-2 my-auto"},[_c('router-link',{attrs:{"to":{
              name: 'show-fdr',
              params: {
                id: item.id,
                slug: item.slug,
              },
            }}},[_vm._v(_vm._s(item.name))])],1),_c('div',{staticClass:"fl_3 d-flex align-items-center"},[_c('div',{attrs:{"data-toggle":"tooltip","data-html":"true"}},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                  item.responsable.firstname + ' ' + item.responsable.lastname
                ),expression:"\n                  item.responsable.firstname + ' ' + item.responsable.lastname\n                ",modifiers:{"hover":true}}],staticClass:"mr-1",staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd","height":"30px","width":"30px"},attrs:{"src":_vm.IMG_URL + item.responsable.avatarUrl}},[(!item.responsable.avatarUrl)?_c('div',{staticStyle:{"width":"25px","height":"25px","border-radius":"15px","display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(item.responsable.firstname[0].toUpperCase() + item.responsable.lastname[0].toUpperCase())+" ")]):_vm._e()])],1)]),_c('p',{staticClass:"px-2 m-auto text-center"},[(item.initiatives.length)?_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""},on:{"click":function($event){return _vm.$emit('showInitiatives', item.id)}}},[_vm._v(_vm._s(item.initiatives.length))]):_c('b-badge',{staticStyle:{"cursor":"pointer","background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"pill":""}},[_vm._v(_vm._s(item.initiatives.length))])],1)])])}),(_vm.loadingData)?_c('div',[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]):(!_vm.items.length)?_c('div',[_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]):_vm._e()],2),_c('div',{staticClass:"col-sm-9 m-0 p-0"},[(_vm.ganttData.length)?_c('frappe-gantt',{staticClass:"w-100 h-100",attrs:{"view-mode":_vm.mode,"tasks":_vm.ganttData.map((item) => ({
            id: `task-${item.id}`,
            progress: item.avancement,
            name: `${item.avancement}%`,
            taskId: item.id,
            start: _vm.formatDate(item.startedAt),
            end: _vm.formatDate(item.endingAt),
          }))},on:{"task-updated":function($event){return _vm.debugEventLog.push($event)},"task-date-updated":_vm.updateFdrDate,"task-progress-updated":_vm.updateFdrProgress}}):_vm._e()],1)]),_c('b-pagination',{attrs:{"total-rows":_vm.items.length,"per-page":10,"aria-controls":"table-list","align":"right"},model:{value:(_vm.ganttCurrentPage),callback:function ($$v) {_vm.ganttCurrentPage=$$v},expression:"ganttCurrentPage"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"header_sd_menu d-flex align-items-center"},[_c('p',{staticClass:"fl_2 m-auto px-2"},[_vm._v("Projet")]),_c('p',{staticClass:"fl_2 m-auto px-2"},[_vm._v("Responsables")]),_c('p',{staticClass:"fl_2 px-2 m-auto text-center"},[_vm._v("Initiatives")])])
}]

export { render, staticRenderFns }