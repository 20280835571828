var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row card m-0"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"p-2 d-flex flex-column"},[(
          _vm.selectedFilter === 1 &&
          _vm.applicationsList.length &&
          _vm.transformationPlan === 'To Be'
        )?_c('div',_vm._l((_vm.groupBy(
            _vm.applicationsList,
            'capacite.id'
          )),function(transformation,index){return _c('div',{key:index},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].capacite && transformation[0].capacite.name ? transformation[0].capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[(!tran)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" section vide ")]):_c('b-link',{staticClass:"rounded px-3 py-2 d-inline-block text-white text-decoration-none",staticStyle:{"cursor":"pointer","background":"gray"},attrs:{"to":{
                      name: 'show-application',
                      params: {
                        id: tran.id,
                        slug: tran.slug || 'abcde',
                      },
                    }}},[_vm._v(" "+_vm._s(tran.name)+" ")])],1)])])}),0),(!transformation.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()])}),0):_vm._e(),(
          _vm.selectedFilter === 2 &&
          _vm.composantsList.length &&
          _vm.transformationPlan === 'To Be'
        )?_c('div',_vm._l((_vm.groupBy(
            _vm.composantsList,
            'capacite.id'
          )),function(transformation,index){return _c('div',{key:index},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].capacite && transformation[0].capacite.name ? transformation[0].capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[(!tran)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" section vide ")]):_c('b-link',{staticClass:"rounded px-3 py-2 d-inline-block text-white text-decoration-none",staticStyle:{"cursor":"pointer","background":"gray"},attrs:{"to":{
                      name: 'show-composant',
                      params: {
                        id: tran.id,
                        slug: tran.slug || 'abcde',
                      },
                    }}},[_vm._v(" "+_vm._s(tran.name)+" ")])],1)])])}),0),(!transformation.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()])}),0):_vm._e(),(!_vm.items.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):(
          _vm.items[0].typeObject === 'Application' &&
          _vm.transformationPlan !== 'To Be'
        )?_vm._l((_vm.groupBy(_vm.graphItems, 'capacite.id')),function(transformation,index){return _c('div',{key:index},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].capacite ? transformation[0].capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[_c('b-link',{class:'rounded px-3  py-2  d-inline-block text-white text-decoration-none ' +
                    _vm.setBgColor(tran.type),staticStyle:{"cursor":"pointer"},attrs:{"to":{
                    name: 'show-application',
                    params: {
                      id: tran.application.id,
                      slug: tran.application.slug || 'abcde',
                    },
                  }}},[_vm._v(" "+_vm._s(tran.application.name)+" ")]),(!tran.application)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" section vide ")]):_vm._e()],1)])])}),0),(!transformation.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e()])}):(
          _vm.items[0].typeObject === 'Composant Technique' &&
          _vm.transformationPlan !== 'To Be'
        )?_vm._l((_vm.groupBy(_vm.graphItems, 'capacite.id')),function(transformation,i){return _c('div',{key:i,staticClass:"w-100 mb-3"},[_c('p',{staticClass:"text-secondary pb-0"},[_vm._v(" "+_vm._s(transformation[0].capacite ? transformation[0].capacite.name : "N/A")+" ")]),_c('div',{staticClass:"row rounded bg-light shadow-sm border col-12 mx-auto mb-3 p-0"},_vm._l((transformation),function(tran,i){return _c('div',{key:'tran' + i,staticClass:"col-12 col-md-2"},[_c('div',{staticClass:"py-2"},[_c('div',[(!tran.composant)?_c('div',{staticClass:"text-gray text-center w-100"},[_c('i',{staticClass:"mdi mdi-information-outline my-auto"}),_vm._v(" section vide ")]):_c('b-link',{class:'rounded px-3  py-2  d-inline-block text-white text-decoration-none ' +
                    _vm.setBgColor(tran.type),staticStyle:{"cursor":"pointer"},attrs:{"to":{
                    name: 'show-composant',
                    params: {
                      id: tran.composant ? tran.composant.id : 0,
                      slug: tran.composant ? tran.composant.slug : 'abcde',
                    },
                  }}},[_vm._v(" "+_vm._s(tran.composant.name)+" ")])],1)])])}),0)])}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }