<template>
  <div style="margin-top: -50px">
    <p class="text-secondary my-auto mr-auto">
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Patrimoine :
      </span>
      <span style="font-size: 22px">{{ libelle }}</span>
    </p>
    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0">Recherche rôle:</span>
      <b-form-input placeholder="rôle" v-model="searchQuery.role" />
      <b-button
        class="ml-1"
        @click="$emit('cofirmRole', selectedRole) && $emit('close')"
        :disabled="selectedRole === null"
        type=""
      >
        Confirmer
      </b-button>
    </div>

    <b-table
      :fields="fields"
      :items="filteredRoles"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loadingData"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedRole"
            :value="data.item"
          ></b-form-radio>
        </div>
      </template>
      <template #cell(role)="data">
        <span> {{ data.item }}</span>
      </template>
      <template #cell(organisation)="data">
        <span> {{ data.value ? data.value : "-" }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    libelle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedRole: null,
      selectedUser: null,
      loadingData: true,
      searchQuery: {
        role: "",
      },
      fields: [
        { key: "role", label: "Role" },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchFdrStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchFdrTypes");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store
      .dispatch("cRole/fetchAllRoles")
      .then(() => (this.loadingData = false));
  },
  computed: {
    ...mapGetters("cRole", ["ROLES"]),
    filteredRoles() {
      return this.ROLES.map(({ name }) => name).filter(
        (role) =>
          role.toLowerCase().indexOf(this.searchQuery.role.toLowerCase()) > -1
      );
    },
    rows() {
      return this.filteredRoles.length;
    },
  },
};
</script>

<style></style>
