<template>
  <div>
    <div class="d-flex mb-2">
      <span class="h4 text-secondary mr-auto my-auto">Liste des KPI </span>

      <div class="d-flex justify-content-end">
        <b-button
          :disabled="disabled ? true : false"
          variant="secondary"
          class="mb-2 align-btn-table-Fdr-lg"
          @click="kpi.tableItems.push({ editMode: true })"
          style="
            background-color: #007bffff !important;
            border-color: #007bffff !important;
          "
          ><i class="mdi mdi-plus"></i> Ajouter KPI</b-button
        >
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>

    <div class="row">
      <div class="col-12 col-md-12">
        <b-table
          :items="kpi.tableItems"
          :fields="kpi.tableFields"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          bordered
          striped
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(progress)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(deadline)="data">
            <div style="font-size: 13px !important">
              <span class="mb-0 d-block">{{ data.value.start_date }}</span>
              <span class="mb-0 d-block">{{ data.value.end_date }}</span>
            </div>
          </template>

          <template v-slot:cell(responsable)="data">
            <div>
              <b-avatar
                v-b-tooltip.hover="data.value.full_name"
                variant="primary"
                size="2rem"
                :src="data.value.img"
                class="mr-1"
              ></b-avatar>
              {{ data.value.name }}
            </div>
          </template>

          <template v-slot:cell(name)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}</span
            >
            <b-form-input v-else placeholder="Nom" />
          </template>

          <template v-slot:cell(value)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}</span
            >
            <b-form-input v-else placeholder="Valeur" />
          </template>
          <template v-slot:cell(code)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}</span
            >
            <b-form-input v-else placeholder="Code" />
          </template>
          <template v-slot:cell(goals_count)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              {{ data.value }}</span
            >
            <b-form-input type="number" v-else placeholder="Objectifs" />
          </template>
          <template v-slot:cell(actions)="data">
            <table-actions
              :actions="data.value"
              :editMode="data.item.hasOwnProperty('editMode')"
              @cancelItem="removeTeamInputs(data.index)"
              @showItem="showSa = !showSa"
              @transferItem="transferProcess = !transferProcess"
            />
          </template>
        </b-table>
      </div>
    </div>

    <div v-if="showGoals" to="modals-xyz-548">
      <modal @close="showGoals = !showGoals">
        <kpi-goal-list />
      </modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import KpiGoalList from "./KpiGoalList.vue";
import Modal from "@/components/custom/Modal.vue";
export default {
  components: { TableActions, KpiGoalList, Modal },
  props: {
    disabled: Boolean,
  },
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    showGoals: false,
    kpi: {
      tableFields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "value", label: "Valeur", sortable: true },
        { key: "code", label: "Code", sortable: true },
        { key: "goals_count", label: "Objectifs", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
      tableItems: [],
    },
  }),
};
</script>

<style></style>
