<template>
  <div style="margin-top: -50px">
    <p class="text-secondary my-auto mr-auto">
      <i :class="$route.meta.iconClass"></i>
      <span style="font-size: 18px" class="font-weight-light">
        Patrimoine :
      </span>
      <span style="font-size: 22px">{{ libelle }}</span>
    </p>
    <br />
    <p class="h3">Ajouter FdR</p>

    <hr />
    <div class="d-flex align-items-center pb-2 pt-0">
      <span class="d-inline-block w-75 h4 mb-0">Recherche FdR:</span>
      <b-form-input placeholder="fdr" v-model="searchQuery.name" />
      <b-button class="ml-1" @click="addFdr" :disabled="selectedRole === null">
        Confirmer
      </b-button>
    </div>

    <b-table
      :fields="fields"
      :items="fdrsList"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loadingData"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedRole"
            :value="data.item.id"
          ></b-form-radio>
        </div>
      </template>
      <template #cell(patrimoine)="data">
        <b-link
          v-if="data.value"
          :to="{
            name: 'show-patrimony',
            params: {
              id: data.value.id,
              slug: data.value.slug || 'abcde',
            },
          }"
        >
          {{ data.value.name }}</b-link
        >
        <span v-else>-</span>
      </template>
      <template #cell(capacites)="data">
        <b-link
          v-if="capacitePrincipale(data.value)"
          :to="{
            name: 'show-infosys-capacity',
            params: {
              id: capacitePrincipale(data.value).id,
              slug: capacitePrincipale(data.value).slug || 'abcde',
            },
          }"
        >
          {{ capacitePrincipale(data.value).name }}</b-link
        >
        <span v-else>-</span>
      </template>
      <template #cell(name)="data">
        <b-link
          :to="{
            name: 'show-application',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
        >
          {{ data.value }}</b-link
        >
      </template>
      <template v-slot:cell(avancement)="data">
        <b-progress
          max="100"
          show-progress
          height="15px"
          style="background-color: #cacccd !important"
        >
          <b-progress-bar
            :style="`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
            :value="data.value"
            :label="`${data.value}%`"
          ></b-progress-bar>
        </b-progress>
      </template>

      <template v-slot:cell(deadline)="data">
        <span
          v-b-tooltip.hover="
            echeanceStatus(
              data.item.createdAt,
              data.item.endingAt,
              data.item.status
            ).toUpperCase()
          "
          class="text-white text-center px-2 py-1 rounded"
          :style="{
            backgroundColor: dateBackgroundColor(
              data.item.createdAt,
              data.item.endingAt,
              data.item.status
            ),
          }"
          >{{
            moment(data.value.end_date, "DD/MM/YYYY").format("DD/MM/YYYY")
          }}</span
        >
      </template>
      <template v-slot:cell(responsable)="data">
        <TableResponsable :responsable="data.value" />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import moment from "moment";
import TableResponsable from "@/components/custom/TableResponsable.vue";

export default {
  components: {
    TableResponsable,
  },
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedRole: null,
      selectedUser: null,
      loadingData: true,
      selectrole: "",
      searchQuery: {
        name: "",
      },
      selected: null,
      fields: [
        { key: "name", label: "Libellé" },
        { key: "status", label: "Statut" },
        { key: "responsable", label: "Responsable" },
        { key: "avancement", label: "Avancement" },
        { key: "patrimoine", label: "Patrimoine" },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  validations: {
    selectrole: {
      required,
    },
  },
  created() {
    this.$store.dispatch("fdr/fetchAllFdrs").then(() => {
      this.loadingData = false;
    });
  },
  methods: {
    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },
    capacitePrincipale(capacites) {
      var capacitePrincipale;
      if (capacites.length) {
        capacitePrincipale = capacites.find((capacite) => capacite.parent)
          ? capacites.find((capacite) => capacite.parent).parent
          : capacites[0];
      }
      return capacitePrincipale;
    },
    addFdr() {
      if (this.selectedRole)
        this.$store
          .dispatch("fdr/updateFdr", {
            id: this.selectedRole,
            patrimoine: this.PATRIMOINE["@id"],
          })
          .then(() => {
            this.$store
              .dispatch("patrimoine/fetchFdr", this.$route.params.id)
              .then(() => {
                this.$emit("close");
                Swal.fire("La FdR est bien créée!", "", "success");
              });
          });
    },
    onRowSelected(item) {
      this.selected = item[0];
    },
  },
  computed: {
    ...mapGetters("fdr", ["FDRS"]),
    ...mapGetters("patrimoine", ["PATRIMOINE"]),
    rows() {
      return this.fdrsList.length;
    },
    fdrsList() {
      return this.FDRS.filter((fdr) =>
        fdr.name.includes(this.searchQuery.name)
      ).filter((fdr) =>
        this.patrimoine && this.patrimoine.feuilles
          ? !this.patrimoine.feuilles.find((feuille) => feuille.id === fdr.id)
          : true
      );
    },
  },
  props: {
    patrimoine: Object,
    libelle: String,
  },
};
</script>

<style></style>
