<template>
  <div>
    <div v-if="disabled == true" class="row">
      <div class="col-12 d-flex mb-2 mb-md-0">
        <div class="my-auto mr-2">
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des processus
          </span>
        </div>
        <DropDownCustom
          :items="['Tableau', 'Graphique']"
          @changeValue="
            (value) =>
              this.$store.dispatch(
                'views/changeProcessusMiterActiveView',
                value
              )
          "
          :value="$store.state.views.processusMiterActiveView"
          class="mr-2 mb-2"
        />
        <div class="col-md-0 ml-auto p-0 d-flex">
          <b-button
            disabled
            variant="outline-secondary"
            class="mb-2 align-btn-table-lg-pr"
            >Mon portfeuille de processus</b-button
          >
          <b-button
            disabled
            variant="secondary"
            class="mb-2 align-btn-table-lg-pr"
            @click="showAddProcess = !showAddProcess"
            style="
              background-color: #007bffff !important;
              border-color: #007bffff !important;
            "
            ><i class="mdi mdi-plus"></i> Ajouter un processus</b-button
          >
        </div>
      </div>
    </div>

    <div v-else class="row p-0">
      <div class="col-12 d-flex mb-2 mb-md-0">
        <div class="my-auto mr-2">
          <span class="h4 text-secondary mr-auto my-auto"
            >Liste des processus
          </span>
        </div>
        <DropDownCustom
          :items="['Tableau', 'Graphique']"
          @changeValue="
            (value) =>
              this.$store.dispatch(
                'views/changeProcessusMiterActiveView',
                value
              )
          "
          :value="$store.state.views.processusMiterActiveView"
          class="mr-2 my-auto"
        />
        <div class="col-md-0 ml-auto p-0 d-flex">
          <b-button
            variant="outline-secondary"
            class="my-auto align-btn-table-lg-pr"
            @click="myProcessus = !myProcessus"
            >Mon portfeuille de processus</b-button
          >

          <b-button
            variant="secondary"
            class="my-auto align-btn-table-lg-pr"
            @click="showAddProcess = !showAddProcess"
            style="
              background-color: #007bffff !important;
              border-color: #007bffff !important;
            "
            ><i class="mdi mdi-plus"></i> Ajouter un processus</b-button
          >
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div v-if="disabled == true" class="row mb-3">
      <div class="col-12 d-flex flex-wrap">
        <div class="pr-2" style="width: 150px">
          <b-form-select
            disabled
            class="mr-2 h-100"
            :options="['Statut SA', 'Statut 1']"
          ></b-form-select>
        </div>
        <div class="pr-2" style="width: 150px">
          <b-form-select
            disabled
            class="mr-2 h-100"
            :options="['PO', 'Tous']"
          ></b-form-select>
        </div>
        <div class="pr-2" style="width: 150px">
          <b-form-input
            disabled
            class="mr-2"
            type="text"
            placeholder="Libellé SA"
          ></b-form-input>
        </div>
        <div class="pr-2" style="width: 150px">
          <b-form-select
            disabled
            class="mr-2 h-100"
            :options="['Domaine SA']"
          ></b-form-select>
        </div>
      </div>
    </div>

    <div v-else class="row mb-3">
      <div class="col-12 d-flex flex-wrap">
        <div class="pr-2" style="width: 200px">
          <v-select
            v-model="statut"
            class="mr-2 h-100"
            placeholder="Statut SA"
            :options="['En prod', 'En cours', 'Terminé']"
          >
            <template #no-options> Liste vide </template>
          </v-select>
        </div>
        <div class="pr-2" style="width: 200px">
          <v-select
            v-model="responsable"
            class="mr-2 h-100"
            :options="responsables"
            :reduce="(responsable) => responsable.id"
            placeholder="PO"
            label="value"
          >
            <template #no-options> Liste vide </template>
          </v-select>
        </div>
        <div class="pr-2" style="width: 200px">
          <b-form-input
            v-model="name"
            class="mr-2"
            type="text"
            placeholder="Libellé Processus"
          ></b-form-input>
        </div>
      </div>
    </div>
    <div class="row" v-if="$store.state.views.processusMiterActiveView == 0">
      <div class="col-12">
        <b-table
          :items="processusData"
          :fields="processus.tableFields"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          bordered
          striped
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(taux)="data">
            <b-progress max="100" show-progress height="15px">
              <b-progress-bar
                :style="`background:${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                };font-size:8px;`"
                :value="data.value"
                :label="`${data.value}%`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(applications)="data">
            <div class="text-center">
              <b-badge
                variant="primary"
                pill
                @click="listApplicationMethods(data.item.id)"
                style="
                  cursor: pointer;
                  background-color: #adb5bd;
                  border-color: #adb5bd;
                "
                >{{ data.value }}</b-badge
              >
            </div>
          </template>

          <template v-slot:cell(code)="data">
            <span
              style="text-decoration: underline; color: blue; cursor: pointer"
              >{{ data.value }}</span
            >
          </template>
          <template v-slot:cell(sousprocessus)="data">
            <div class="text-center">
              <b-link
                v-if="data.item.parent"
                :to="{
                  name: 'show-job-process',
                  params: {
                    id: data.item.parent.id,
                    slug: data.item.parent.slug || 'abcde',
                  },
                }"
              >
                {{ data.item.parent.name }}
              </b-link>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:cell(businessOwner)="data">
            <div>
              <TableResponsable :responsable="data.value"></TableResponsable>
            </div>
          </template>
          <template v-slot:cell(description)="data">
            <div>{{ data.value || "-" }}</div>
          </template>
          <template v-slot:cell(name)="data">
            <b-link
              @click="
                $router.push({
                  name: 'show-job-process',
                  params: {
                    slug: data.item.slug ? data.item.slug : '8787',
                    id: data.item.id,
                  },
                })
              "
              >{{ data.value }}</b-link
            >
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="disabled ? [] : ['delete', 'transfer']"
              :editMode="data.item.hasOwnProperty('editMode')"
              @transferItem="transferData(data.item)"
              @deleteItem="deleteItem(data.item.id)"
            ></table-actions>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        ></b-pagination>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-12">
        <GraphicViewPro
          :items="processusData"
          @showItem="showProcess = !showProcess"
        />
      </div>
    </div>

    <div v-if="showAddProcess" to="modals-xyz-548">
      <modal @close="showAddProcess = !showAddProcess">
        <add-process
          :items="processusData"
          :libelle="PROCESSUS"
          @addprocessus="addprocessus"
        />
      </modal>
    </div>

    <div v-if="showApplicationProcessList" to="modals-xyz-548">
      <modal @close="showApplicationProcessList = !showApplicationProcessList">
        <process-application-list :items="listApplication" />
      </modal>
    </div>

    <div v-if="showProcess" to="modals-xyz-548">
      <modal @close="showProcess = !showProcess">
        <show-process :items="data" />
      </modal>
    </div>

    <div v-if="transferProcess" to="modals-xyz-548">
      <modal @close="transferProcess = !transferProcess">
        <transfer-process
          :items="transfer"
          :libelle="PROCESSUS"
          @transferproc="transferItem"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "@/components/custom/DropDown.vue";
import TableActions from "@/components/custom/TableActions.vue";
import AddProcess from "./AddProcess.vue";
import Modal from "@/components/custom/Modal.vue";
import ProcessApplicationList from "./ProcessApplicationList.vue";
import ShowProcess from "./ShowProcess.vue";
import TransferProcess from "./TransferProcess.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import GraphicViewPro from "./GraphicViewPro";
import TableResponsable from "@/components/custom/TableResponsable";

export default {
  components: {
    GraphicViewPro,
    DropDownCustom,
    TableActions,
    AddProcess,
    TableResponsable,
    Modal,
    ProcessApplicationList,
    ShowProcess,
    TransferProcess,
  },
  props: {
    disabled: Boolean,
    items: [Object, Array],
  },
  data: () => ({
    data: null,
    statut: "",
    responsable: "",
    domaine: "",
    name: "",
    sortBy: "",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    showAddProcess: false,
    showProcess: false,
    transferProcess: false,
    showApplicationProcessList: false,
    listApplication: null,
    transfer: null,
    myProcessus: false,

    processus: {
      tableItems: [],
      tableFields: [
        { key: "code", label: "Code", sortable: true },
        { key: "name", label: "Processus", sortable: true },
        { key: "niveau", label: "Niveau", sortable: true },
        { key: "businessOwner", label: "Business owner", sortable: true },
        { key: "description", label: "Description", sortable: true },
        {
          key: "sousprocessus",
          label: "Processus de rattachement",
          sortable: true,
        },
        {
          key: "applications",
          label: "Applications",
          sortable: true,
        },
        {
          key: "taux",
          label: "Taux de remplissage",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
    },
    selectedView: 0,
  }),
  created() {
    if (this.$route.params.id) {
      this.$store.dispatch("users/fetchAllResponsables");
      this.$store
        .dispatch("patrimoine/fetchProcessus", this.$route.params.id)
        .then(() => (this.loading = false));
    } else {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("processusMetier", ["APPLICATIONS"]),
    ...mapGetters("patrimoine", ["PROCESSUS"]),
    ...mapGetters(["AuthenticatedUser"]),
    rows() {
      return this.processusData.length;
    },

    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));
      return data;
    },
    processusData() {
      if (this.PROCESSUS ? this.PROCESSUS.processus : false) {
        return this.PROCESSUS.processus
          .map((data) => ({
            ...data,
            code: "PM-" + data.id,
            businessOwner: data.businessOwner ? data.businessOwner : null,
            applications: data.applications.length,
            applicationsdata: data.applications,
          }))
          .filter(
            (processus) =>
              processus.name.toLowerCase().indexOf(this.name.toLowerCase()) >
                -1 &&
              (this.responsable
                ? processus.businessOwner.id === this.responsable
                : true)
          )
          .filter((processus) =>
            this.myProcessus &&
            this.AuthenticatedUser &&
            processus.businessOwner
              ? this.AuthenticatedUser.id === processus.businessOwner.id
              : true
          )
          .filter((application) => {
            if (this.statut) {
              if (application.applicationsdata.length) {
                return application.applicationsdata.filter(
                  (application) => application.status === this.statut
                ).length;
              } else return false;
            } else return true;
          });
      } else {
        return [];
      }
    },
  },
  methods: {
    onValueChange(e) {
      this.selectedView = e;
    },

    display(id) {
      this.data = id;
      this.showProcess = !this.showProcess;
    },
    addprocessus(index) {
      this.$emit("addprocessus", index);
      Swal.fire("Le processus est bien créé !", "", "success");
      this.showAddProcess = !this.showAddProcess;
    },
    deleteItem(index) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var data = this.PROCESSUS.processus
            .filter((data) => data.id !== index)
            .map((data) => data["@id"]);
          this.$emit("deleteProcessus", data);
          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
    listApplicationMethods(id) {
      this.$store.dispatch("processusMetier/fetchApplication", id).then(() => {
        if (this.APPLICATIONS.applications.length > 0) {
          this.listApplication = this.APPLICATIONS;

          this.showApplicationProcessList = !this.showApplicationProcessList;
        }
      });
    },
    transferData(data) {
      this.transfer = data;
      this.transferProcess = !this.transferProcess;
    },
    transferItem(item) {
      this.$store
        .dispatch("processusMetier/updateProcessusMetier", item)
        .then(() => {
          this.$store
            .dispatch("patrimoine/fetchProcessus", this.$route.params.id)
            .then(() => {
              this.transferProcess = !this.transferProcess;
              Swal.fire("Le processus est bien transféré !", "", "success");
            });
        });
    },
  },
};
</script>

<style></style>
